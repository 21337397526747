import { SectionGridItem } from 'common/components/SectionGrid/SectionGridItem';
import { SectionGridRow } from 'common/components/SectionGrid/SectionGridRow';
import { useTranslation } from 'react-i18next';
import { useSectionAvailability } from '../../SectionGrid/hooks/useSectionAvailability';

type OrderData = {
  connectionId?: string | null;
  orderNumber?: string | null;
  customerReference?: string | null;
};

export type OrderSectionProps = {
  orderData: OrderData;
};

export const OrderSection = ({ orderData }: OrderSectionProps) => {
  const { t } = useTranslation();

  const available = useSectionAvailability({
    data: orderData,
    props: ['connectionId', 'orderNumber', 'customerReference'],
  });

  return available ? (
    <SectionGridRow title={t('simInventory.order')} data-testid="orderSection">
      <>
        <SectionGridItem
          testId="connectionId"
          value={orderData.connectionId}
          label={t('simInventory.connectionId')}
        />
        <SectionGridItem
          testId="orderNumber"
          value={orderData?.orderNumber}
          label={t('simInventory.orderNumber')}
        />
        <SectionGridItem
          testId="customerReference"
          value={orderData?.customerReference}
          label={t('products.sim.orderReference')}
        />
      </>
    </SectionGridRow>
  ) : null;
};
