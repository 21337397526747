import React from 'react';
import Box from '@mui/material/Box';
import { AdditionalMenu } from 'common/components/AdditionalMenu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { GoToDetailsMenuItem } from './GoToDetailsMenuItem';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { Routes } from '../../../../Routes.constants';
import { useCustomFields } from '../../../../admin/customFields/hooks/useCustomFields';
import { useHasFeatureFlag } from '../../../../featureFlags/useHasFeatureFlag';

type RowSettingsProps = {
  iccid: string;
  setEditedSim: (iccid: string) => void;
  baseRoute: Routes;
  linkText: string;
};

export const RowSettings: React.FC<RowSettingsProps> = ({
  iccid,
  setEditedSim,
  baseRoute,
  linkText,
}) => {
  const ability = useAbility();
  const editingSimAvailable =
    ability.can(Actions.edit, Subjects.tags) || ability.can(Actions.edit, Subjects.customFields);

  const { customFields } = useCustomFields();
  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');
  const customFieldsMigratedToProductsView = useHasFeatureFlag(
    'CustomFieldsMigratedToProductsView',
  );

  return (
    <Box>
      <AdditionalMenu
        children={({ onClose }) => (
          <MenuList sx={{ p: 0 }}>
            <GoToDetailsMenuItem
              onClose={onClose}
              iccid={iccid}
              baseRoute={baseRoute}
              linkText={linkText}
            />
            {((!tagsMigratedToProductsView && !customFieldsMigratedToProductsView) ||
              (customFieldsMigratedToProductsView && !tagsMigratedToProductsView) ||
              (tagsMigratedToProductsView &&
                !customFieldsMigratedToProductsView &&
                !!customFields &&
                customFields.length > 0)) &&
              editingSimAvailable && (
                <MenuItem
                  onClick={() => {
                    setEditedSim(iccid);
                    onClose();
                  }}
                >
                  <ListItemText>Edit</ListItemText>
                </MenuItem>
              )}
          </MenuList>
        )}
      ></AdditionalMenu>
    </Box>
  );
};
