import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StickyCopyExtension } from 'common/StickyCopyExtension/StickyCopyExtension';

const IconCellRoot = styled('div')({
  display: 'flex',
  gap: '4px',
  width: '100%',
});

const IconBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const TextsBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'calc(100% - 24px)',
});

export type IconCellProps = {
  icon?: React.ReactNode;
  primaryText?: string;
  secondaryText?: string;
};

export const IconCell = ({ icon, primaryText, secondaryText }: IconCellProps) => {
  return (
    <IconCellRoot>
      <IconBox>{icon}</IconBox>
      <TextsBox>
        {primaryText && (
          <div data-testid="primaryText">
            <StickyCopyExtension value={primaryText}>
              <Typography variant="text1" color="text.primary">
                {primaryText}
              </Typography>
            </StickyCopyExtension>
          </div>
        )}
        {secondaryText && (
          <div data-testid="secondaryText">
            <StickyCopyExtension value={secondaryText}>
              <Typography variant="text5" color="text.secondary">
                {secondaryText}
              </Typography>
            </StickyCopyExtension>
          </div>
        )}
      </TextsBox>
    </IconCellRoot>
  );
};
