import { useAtom, WritableAtom } from 'jotai';
import { SetStateAction } from 'react';
import { Chip } from '../Chips/Chip';
import { isEqual } from 'lodash';
import { useCustomFieldFromQueryCache } from 'admin/customFields/hooks/useCustomFieldFromQueryCache';

type CustomFieldsProps = {
  customFieldsAtom: WritableAtom<
    Record<string, string>,
    [SetStateAction<Record<string, string>>],
    void
  >;
};

export const CustomFieldsChips = ({ customFieldsAtom }: CustomFieldsProps) => {
  const [customFields, setCustomFields] = useAtom(customFieldsAtom);

  const { data: customFieldsData, isFetched } = useCustomFieldFromQueryCache();

  const idLabelMap = Object.keys(customFields).reduce<Record<string, string>>(
    (currentIdLabelMap, customFieldId: string) => {
      const customField = customFieldsData?.find(
        (customFieldData) => customFieldData.id.toString() === customFieldId,
      );

      return {
        ...currentIdLabelMap,
        [customFieldId]: customField?.label || '',
      };
    },
    {},
  );

  const entries = Object.entries(customFields).filter(
    ([, customFieldFilterValue]) =>
      customFieldFilterValue !== '' && !isEqual(customFieldFilterValue, []),
  );

  return (
    <>
      {entries.map(([customFieldId, customFieldFilterValue]) => {
        const customFieldName = idLabelMap[customFieldId];
        let customFieldValue = customFieldFilterValue;
        if (Array.isArray(customFieldFilterValue)) {
          const customField = customFieldsData?.find(
            (value) => value.id.toString() === customFieldId,
          );
          return customFieldFilterValue.map((id) => {
            customFieldValue =
              customField?.selectionOptions.find((option) => option.id?.toString() === id)?.label ||
              '';

            return (
              <Chip
                label={`${customFieldName}: ${customFieldValue}`}
                size="medium"
                variant="outlined"
                isLoading={!isFetched}
                onDelete={() => {
                  const customField = customFieldsData?.find(
                    (customField) => customField.id.toString() === customFieldId,
                  );
                  //@ts-ignore
                  setCustomFields((customFields) => ({
                    ...customFields,
                    [customFieldId]: customField?.dataType === 'Dropdown' ? [] : '',
                  }));
                }}
              />
            );
          });
        }

        return (
          <Chip
            label={`${customFieldName}: ${customFieldValue}`}
            size="medium"
            variant="filled"
            isLoading={!isFetched}
            onDelete={() => {
              const customField = customFieldsData?.find(
                (customField) => customField.id.toString() === customFieldId,
              );
              //@ts-ignore
              setCustomFields((customFields) => ({
                ...customFields,
                [customFieldId]: customField?.dataType === 'Dropdown' ? [] : '',
              }));
            }}
          />
        );
      })}
    </>
  );
};
