import { Link, Navigate, Outlet, Route, Routes as RouterRoutes } from 'react-router-dom';
import { SimInventoryTab } from 'simInventory/SimInventory';
import { SimInventoryEntry } from 'simInventory/SimInventoryDetails/SimInventoryEntry';
import { RSimInventoryTab } from 'rSimInventory/RSimInventory';
import { Routes } from './Routes.constants';
import { BulkProvisioningJobs } from 'bulkProvisioning/BulkProvisioningJobs';
import { BulkProvisioningJobs as BulkProvisioningJobsOLD } from 'bulkProvisioning/BulkProvisioningJobsOLD';
import { BulkProvisioningJob } from 'bulkProvisioning/BulkProvisioningJob';
import { TabAppLayout } from 'common/components/AppLayout/TabAppLayout';
import Tab from '@mui/material/Tab';
import { tabStyle } from 'common/components/AppLayout/AppLayout.styles';
import { SimInventoryTabsLayout } from 'simInventory/SimInventoryDetails/SimInventoryTabsLayout';
import { BorderBox } from 'common/components/AppLayout/BorderBox';
import { SessionHistoryWithInterims } from 'simInventory/SessionHistory/SessionHistoryWithInterims';
import { HistoryAnalysis } from 'simInventory/SessionHistory/HistoryAnalysis/HistoryAnalysis';
import { RSimHistoryAnalysis } from 'rSimInventory/HistoryAnalysis/RSimHistoryAnalysis';
import { RSimInventoryTabsLayout } from 'rSimInventory/Components/RSimInventoryTabsLayout';
import { RSimInventoryEntry } from './rSimInventory/Components/RSimInventoryDetails/RSimInventoryEntry';
import { RSimSessionHistoryEntry } from './rSimInventory/Components/RSimSessionHistory/RSimSessionHistoryEntry';
import { FeatureFlagResolver } from 'featureFlags/FeatureFlagResolver';
import { AdminTabsLayout } from './admin/AdminTabsLayout';
import { CustomFieldsEntry } from './admin/customFields/CustomFieldsEntry';
import { SearchStoreProvider } from 'simInventory/Components/Filters/SearchStoreProvider';

import { AppNavigation } from './common/components/AppLayout/AppNavigation/AppNavigation';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { AuditTrailPage } from './auditTrail/AuditTrailPage';
import { SingleTabLayout } from 'common/components/AppLayout/SingleTabLayout';
import { LocationStateStoreProvider } from 'common/components/LocationStorage/LocationStateStoreProvider';
import { RSimManufacturingFilePage } from './admin/rSimManufacturingFile/RSimManufacturingFilePage';
import { useHasFeatureFlag } from './featureFlags/useHasFeatureFlag';
import { NavigationTracker } from 'common/components/AppLayout/AppNavigation/NavigationTracker';
import { SimProductsTab } from './products/sim/SimProducts';
import { SimProductsTabsLayout } from './products/sim/Components/SimProductsTabsLayout';
import { SimDetailsProvider } from './simInventory/SimInventoryDetails/SimDetailsProvider';
import ReactGA from 'react-ga4';

type TabsProps = {
  firstTabHighlited?: boolean;
};

const InventoryTabs: React.FC<TabsProps> = ({ firstTabHighlited = false }) => {
  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  return (
    <TabAppLayout firstTabHighlited={firstTabHighlited}>
      <Tab
        value={Routes.simInventory}
        label="SIM"
        component={Link}
        to={Routes.simInventory}
        sx={tabStyle}
      />
      {!productsViewEnabled && (
        <Tab
          sx={tabStyle}
          value={Routes.rSimInventory}
          label="rSIM"
          component={Link}
          to={Routes.rSimInventory}
        />
      )}
    </TabAppLayout>
  );
};

const ProductsTabs: React.FC<TabsProps> = ({ firstTabHighlited = false }) => {
  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const simProductsViewEnabled = useHasFeatureFlag('SimProductsView');
  return (
    <TabAppLayout firstTabHighlited={firstTabHighlited}>
      {simProductsViewEnabled && (
        <Tab
          sx={tabStyle}
          value={Routes.productsSim}
          label="SIM"
          component={Link}
          to={Routes.productsSim}
        />
      )}
      {productsViewEnabled && (
        <Tab
          sx={tabStyle}
          value={Routes.productsRsim}
          label="rSIM"
          component={Link}
          to={Routes.productsRsim}
        />
      )}
    </TabAppLayout>
  );
};

const RsimLoader = () => {
  return <RSimInventoryTab />;
};

const SimInventoryDetailsLoader = () => {
  const ability = useAbility();

  return ability.can('read', 'simDetails') ? (
    <BorderBox>
      <SimDetailsProvider baseRoute={Routes.simInventory}>
        <SimInventoryEntry />
      </SimDetailsProvider>
    </BorderBox>
  ) : (
    <Navigate to={Routes.simInventory} replace />
  );
};

const SimProductsDetailsLoader = () => {
  const ability = useAbility();

  return ability.can('read', 'simDetails') ? (
    <BorderBox>
      <SimDetailsProvider baseRoute={Routes.productsSim}>
        <SimInventoryEntry />
      </SimDetailsProvider>
    </BorderBox>
  ) : (
    <Navigate to={Routes.productsSim} replace />
  );
};

export const AppRoutes = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  const ability = useAbility();
  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const simProductsViewEnabled = useHasFeatureFlag('SimProductsView');

  const productsViewRsimActivationEnabled = useHasFeatureFlag('ProductsViewRsimActivation');

  return (
    <RouterRoutes>
      <Route element={<NavigationTracker />}>
        <Route
          element={
            <>
              <AppNavigation />
              <SingleTabLayout />
            </>
          }
        >
          <Route
            path={Routes.provisioningRequest}
            element={
              !productsViewRsimActivationEnabled ? (
                <BulkProvisioningJobsOLD />
              ) : (
                <BulkProvisioningJobs />
              )
            }
          >
            <Route path=":id" element={<BulkProvisioningJob />} />
          </Route>
        </Route>
        <Route
          element={
            <LocationStateStoreProvider>
              <AppNavigation />
              <Outlet />
            </LocationStateStoreProvider>
          }
        >
          {productsViewEnabled ? (
            <>
              <Route element={<ProductsTabs />}>
                <Route path={Routes.productsRsim} element={<RsimLoader />} />
              </Route>
              <Route element={<RSimInventoryTabsLayout />}>
                <Route path={`${Routes.productsRsim}/:euicc`} element={<RSimInventoryEntry />} />

                <Route
                  path={`${Routes.productsRsim}/:euicc/session-history`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <RSimSessionHistoryEntry />
                    </FeatureFlagResolver>
                  }
                />
                <Route
                  path={`${Routes.productsRsim}/:euicc/history-analysis`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <RSimHistoryAnalysis />
                    </FeatureFlagResolver>
                  }
                />
              </Route>
            </>
          ) : (
            <>
              <Route element={<InventoryTabs />}>
                <Route path={Routes.rSimInventory} element={<RsimLoader />} />
              </Route>

              <Route element={<RSimInventoryTabsLayout />}>
                <Route path={`${Routes.rSimInventory}/:euicc`} element={<RSimInventoryEntry />} />

                <Route
                  path={`${Routes.rSimInventory}/:euicc/session-history`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <RSimSessionHistoryEntry />
                    </FeatureFlagResolver>
                  }
                />
                <Route
                  path={`${Routes.rSimInventory}/:euicc/history-analysis`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <RSimHistoryAnalysis />
                    </FeatureFlagResolver>
                  }
                />
              </Route>
            </>
          )}

          {simProductsViewEnabled && (
            <Route element={<SearchStoreProvider />}>
              <Route element={<ProductsTabs firstTabHighlited />}>
                <Route
                  path={Routes.productsSim}
                  element={
                    <SimDetailsProvider baseRoute={Routes.productsSim}>
                      <BorderBox>
                        <SimProductsTab />
                      </BorderBox>
                    </SimDetailsProvider>
                  }
                />
              </Route>
              <Route element={<SimProductsTabsLayout />}>
                <Route
                  path={`${Routes.productsSim}/:iccid`}
                  element={<SimProductsDetailsLoader />}
                />

                <Route
                  path={`${Routes.productsSim}/:iccid/history`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <SessionHistoryWithInterims />
                    </FeatureFlagResolver>
                  }
                />

                <Route
                  path={`${Routes.productsSim}/:iccid/history-analysis`}
                  element={
                    <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                      <HistoryAnalysis />
                    </FeatureFlagResolver>
                  }
                />
                {ability.can(Actions.read, Subjects.auditTrails) && (
                  <Route
                    element={
                      <BorderBox>
                        <AuditTrailPage />
                      </BorderBox>
                    }
                    path={`${Routes.productsSim}/:iccid/audit-trail`}
                  />
                )}
              </Route>
            </Route>
          )}

          <Route element={<SearchStoreProvider />}>
            <Route element={<InventoryTabs firstTabHighlited />}>
              <Route
                path={Routes.simInventory}
                element={
                  <SimDetailsProvider baseRoute={Routes.simInventory}>
                    <BorderBox>
                      <SimInventoryTab />
                    </BorderBox>
                  </SimDetailsProvider>
                }
              />
            </Route>
            <Route element={<SimInventoryTabsLayout />}>
              <Route
                path={`${Routes.simInventory}/:iccid`}
                element={<SimInventoryDetailsLoader />}
              />

              <Route
                path={`${Routes.simInventory}/:iccid/history`}
                element={
                  <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                    <SessionHistoryWithInterims />
                  </FeatureFlagResolver>
                }
              />

              <Route
                path={`${Routes.simInventory}/:iccid/history-analysis`}
                element={
                  <FeatureFlagResolver requiredFlag="SimCardSessionsHistory">
                    <HistoryAnalysis />
                  </FeatureFlagResolver>
                }
              />
              {ability.can(Actions.read, Subjects.auditTrails) && (
                <Route
                  element={
                    <BorderBox>
                      <AuditTrailPage />
                    </BorderBox>
                  }
                  path={`${Routes.simInventory}/:iccid/audit-trail`}
                />
              )}
            </Route>
          </Route>

          {(ability.can(Actions.edit, Subjects.customFields) ||
            ability.can(Actions.edit, Subjects.importRsims)) && (
            <Route element={<AdminTabsLayout />}>
              {ability.can(Actions.edit, Subjects.customFields) && (
                <Route
                  path={`${Routes.admin}/custom-fields`}
                  element={
                    <BorderBox>
                      <CustomFieldsEntry />
                    </BorderBox>
                  }
                />
              )}
              {ability.can(Actions.edit, Subjects.importRsims) && (
                <Route
                  path={`${Routes.admin}/rsim-manufacturing-file`}
                  element={
                    <BorderBox>
                      <RSimManufacturingFilePage />
                    </BorderBox>
                  }
                />
              )}
            </Route>
          )}
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={
                simProductsViewEnabled
                  ? Routes.productsSim
                  : productsViewEnabled
                  ? Routes.productsRsim
                  : Routes.simInventory
              }
              replace
            />
          }
        />
      </Route>
    </RouterRoutes>
  );
};
