import { FiltersChips } from 'common/components/Filters/FiltersChips';
import {
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  LastActiveProfileFilter,
  LastUpdateTime,
  RSIM_INITIAL_FILTERS,
  RSimFilters,
  SIMULATE_OUTAGE_FILTER,
  SimulateOutageFilter,
  ValidRSimFiltersKeys,
} from './../../data/filters.constants';
import { mapLastUpdateTimeToLabels } from './../../utils/lastUpdateTimeMappers';
import {
  mapLastActiveProfileFilterTLabel,
  mapSimulateOutageFilterToValue,
} from './../../utils/lastActiveProfileFilterMappers';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { rsimFiltersAtom } from 'atoms/filters/rsim-products';
import { useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash';
import { RSimTagsChips } from './RSimTagsChips';
import { RSimAccountChips } from './RSimAccountTags';

type RSimChipsFilters = Omit<RSimFilters, 'searchText' | 'chosenIdType'>;

export const RSimFiltersChips = () => {
  const [filters, setFilters] = useAtom(rsimFiltersAtom);
  const clearAll = useResetAtom(rsimFiltersAtom);

  const onFilterClear = (filterName: ValidRSimFiltersKeys | 'eidRange') => {
    if (filterName === 'eidRange') {
      setFilters((currentFilters) => ({ ...currentFilters, eidFrom: '', eidTo: '' }));
      return;
    }

    setFilters((currentFilters) => {
      return { ...currentFilters, [filterName]: RSIM_INITIAL_FILTERS[filterName] };
    });
  };

  const { t } = useTranslation();
  const createValuesForChips = (
    filters: Omit<RSimChipsFilters, 'tags' | 'accounts' | 'eidFrom' | 'eidTo'>,
  ) => {
    const filtersNames = {
      lastActiveProfile: 'Last active profile',
      lastUpdateTime: 'No update for',
      orderNumber: t('common.orderNumber'),
      connectionId: t('common.connectionId'),
      simulateOutage: t('common.simulateOutage'),
      eidRange: '',
    };

    const chipsValues: Partial<Record<keyof typeof filtersNames, string>> = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'chosenIdType' || key === 'searchText') {
        return;
      }

      if (
        (key === 'lastUpdateTime' && value === LAST_UPDATE_TIME_FILTERS.NONE) ||
        (key === 'lastActiveProfile' && value === LAST_ACTIVE_PROFILE_FILTER.ANY) ||
        (key === 'simulateOutage' && value === SIMULATE_OUTAGE_FILTER.ANY) ||
        (key === 'accounts' && isEqual(value, [])) ||
        (key === 'tags' && isEqual(value, []))
      ) {
        return;
      }

      if (key === 'lastUpdateTime' && value !== LAST_UPDATE_TIME_FILTERS.NONE) {
        const valueText = mapLastUpdateTimeToLabels(value as LastUpdateTime);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'lastActiveProfile' && value !== LAST_ACTIVE_PROFILE_FILTER.ANY) {
        const valueText = mapLastActiveProfileFilterTLabel(value as LastActiveProfileFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (key === 'simulateOutage' && value !== SIMULATE_OUTAGE_FILTER.ANY) {
        const valueText = mapSimulateOutageFilterToValue(value as SimulateOutageFilter);
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${valueText}`;
      } else if (value !== '') {
        chipsValues[key as keyof typeof filtersNames] = `${
          filtersNames[key as keyof typeof filtersNames]
        }: ${value}`;
      }
    });

    return chipsValues;
  };

  const { tags, accounts, eidFrom, eidTo, ...otherFilters } = filters;
  const filtersChips = createValuesForChips(otherFilters);

  if (eidFrom !== '' || eidTo !== '') {
    filtersChips.eidRange = `EID`;
    if (eidFrom) {
      filtersChips.eidRange += ` ${t('common.from')}: ${eidFrom}`;
    }

    if (eidTo) {
      filtersChips.eidRange += ` ${t('common.to')}: ${eidTo}`;
    }
  }

  return (
    <FiltersChips
      externalChips={
        <>
          <RSimTagsChips />
          <RSimAccountChips />
        </>
      }
      filters={filtersChips}
      onFilterClear={onFilterClear as (key: string) => void}
      onFiltersClear={clearAll}
    />
  );
};
