import { FilterAsyncChip } from 'common/components/FilterChip/FilterAsyncChip';
import { SetStateAction, useAtom, WritableAtom } from 'jotai';
import React, { useContext } from 'react';
import { TagContext } from 'tags/TagProvider';

type TagsChipsProps = {
  tagsAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
};

export const TagsChips = ({ tagsAtom }: TagsChipsProps) => {
  const { fetchTag, type: tagType } = useContext(TagContext);
  const [tags, setTags] = useAtom(tagsAtom);

  const onClear = (id: string) => {
    setTags((currentTags) => currentTags.filter((tag) => tag !== id));
  };

  if (tags.length === 0) {
    return null;
  }

  return (
    <>
      {tags.map((tag) => {
        return (
          <React.Fragment key={tag}>
            <FilterAsyncChip
              labelPrefix="Tag"
              onCancel={() => {
                onClear(tag);
              }}
              fieldName="name"
              queryFn={() => {
                return fetchTag(tag);
              }}
              queryKey={['tag', tagType, tag]}
              queryEnabled={!!tag}
              variant="outlined"
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
