import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import {
  fetchSimInventory,
  fetchSimInventoryPagination,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { Sorting } from 'common/hooks/useSorting';
import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { getSearchIds } from '../Components/Inputs/SimIdSearchInput.util';
import { simInventoryQueries } from 'simInventory/Inventory/queries/simInventoryQueries';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { SimInventoryData } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { useDebounce } from 'usehooks-ts';

export const useSimInventoryFetch = (
  filters: SimInventoryFiltersV2,
  sort: { columnName: string; sort: Sorting } | null = null,
  rowsPerPage: number,
  page: number,
) => {
  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;

  const debouncedSearchText = useDebounce(searchText, 500);

  const queryKey = simInventoryQueries.list({
    searchText: debouncedSearchText,
    chosenIdType,
    page,
    rowsPerPage,
    ...otherFilters,
    tags,
    sorting: sort,
    customFields,
  });

  const { data: simInventory, isRefetching } = useSuspenseQuery<SimInventoryData>({
    queryKey: queryKey,
    queryFn: ({ signal }) => {
      return setMinimalPromiseTime(
        fetchSimInventory(
          page,
          rowsPerPage,
          {
            searchText: getSearchIds(searchText),
            chosenIdType: chosenIdType,
            tags,
            customFields,
            ...otherFilters,
          },
          sort,
          true,
          signal,
        ),
      );
    },
    refetchOnWindowFocus: false,
  });

  const { status, data: pagination } = useQuery({
    queryKey: simInventoryQueries.pagination({
      searchText,
      chosenIdType,
      ...otherFilters,
      tags,
      sorting: sort,
      customFields,
    }),
    queryFn: ({ signal }) =>
      fetchSimInventoryPagination(
        page,
        rowsPerPage,
        {
          searchText: getSearchIds(searchText),
          chosenIdType: chosenIdType,
          tags,
          customFields,
          ...otherFilters,
        },
        signal,
      ),
  });

  return {
    simInventory,
    pagination: {
      totalNumberOfItems: status === 'success' ? pagination?.totalNumberOfItems : -1,
    },
    queryKey,
    isRefetching,
  };
};
