import { eidRangeFilterAtom, searchTextFilterAtom } from 'atoms/filters/rsim-products';
import { IdRangeSearch } from 'common/components/Inputs/IdRangeSearch/IdRangeSearch';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const EIDRangeSearch = () => {
  const [eidRange, setEidRange] = useAtom(eidRangeFilterAtom);

  const setSearchText = useSetAtom(searchTextFilterAtom);

  const onChange = useCallback((from: string, to: string) => {
    setEidRange({ eidFrom: from, eidTo: to });
    if (from !== '' || to !== '') {
      setSearchText('');
    }
  }, []);

  return (
    <IdRangeSearch
      data-testid="eid range search"
      from={eidRange.eidFrom}
      to={eidRange.eidTo}
      onChange={onChange}
    />
  );
};
