import { rsimFiltersAtom } from 'atoms/filters/rsim-products';
import { Provider, useAtomValue } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { ReactNode } from 'react';

export const RSimTemporaryHydrateAtoms = ({ initialValues, children }: any) => {
  useHydrateAtoms(initialValues);
  return children;
};

export const RSimFiltersDialogAtomInitializator = ({ children }: { children: ReactNode }) => {
  const filters = useAtomValue(rsimFiltersAtom);

  return (
    <Provider>
      <RSimTemporaryHydrateAtoms initialValues={[[rsimFiltersAtom, filters]]}>
        {children}
      </RSimTemporaryHydrateAtoms>
    </Provider>
  );
};
