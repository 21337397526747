import { TagsData } from 'tags/services/tagsApi.types';
import { LastActiveProfile } from './rSimApi';

export type RSimStatusLabel = 'Active';

export enum RsimProfileStatus {
  Unknown,
  PrimaryProfileActive,
  SecondaryProfileActive,
  PrimaryProfileDisconnected,
  SecondaryProfileDisconnected,
  UnknownDisconnected,
}

export type RSimInventoryItem = {
  id: string;
  status: RsimProfileStatus;
  accountName: string;
  accountNumber: string;
  euicc: string;
  primaryIccid: string;
  secondaryIccid: string;
  dataUsage: number;
  inMinuteUsage: number;
  inTextUsage: number;
  outMinuteUsage: number;
  outTextUsage: number;
  lastPollingTimestamp: string;
  tags?: TagsData;
  appliedOtaConfigurationProfile: string;
  appliedOtaConfigurationVersion: string;
  isPendingConfiguration: boolean;
  isCustomConfigurationDetected: boolean;
  lastActiveProfile: LastActiveProfile;
  connectionId: string;
  orderNumber: string;
  customerReference: string;
  iccidOta: string;
};

export type RSimInventoryData = {
  items: RSimInventoryItem[];
};

export type RSimConfiguration = {
  euicc: string;
  appliedOtaConfigurationProfile: string;
  appliedOtaConfigurationVersion: string;
  isPendingConfiguration: boolean;
  isCustomConfigurationDetected: boolean;
};

export type RSimInventoryResponsePagination = {
  totalCount: number;
  totalPages: number;
};
