import { useSuspenseQuery } from '@tanstack/react-query';
import { ProvisioningFilters } from 'bulkProvisioning/BulkProvisioning.interface';
import { fetchBulkProvisioningJobs } from 'bulkProvisioning/BulkProvisioningApi/bulkProvisioningApi';
import { provisioningQueries } from 'bulkProvisioning/utils/queries';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';

export const useBulkProvisoningFetch = ({
  fileName,
  orderNumber,
  uploadDateFrom,
  uploadDateTo,
  status,
  source,
  page,
  rowsPerPage,
}: ProvisioningFilters & { fileName: string; page: number; rowsPerPage: number }) => {
  const queryResult = useSuspenseQuery({
    queryKey: provisioningQueries.inventory({
      fileName: fileName,
      orderNumber: orderNumber,
      uploadDateFrom: uploadDateFrom,
      uploadDateTo: uploadDateTo,
      status: status,
      source: source,
      page,
      rowsPerPage,
    }),
    queryFn: ({ signal }) =>
      setMinimalPromiseTime(
        fetchBulkProvisioningJobs(
          page,
          rowsPerPage,
          status,
          source,
          uploadDateFrom,
          uploadDateTo,
          fileName,
          orderNumber,
          signal,
        ),
      ),
  });

  return queryResult;
};
