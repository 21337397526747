import { useQuery } from '@tanstack/react-query';
import { customFieldsQuery } from '../utils/queries';
import { fetchCustomFields } from '../api/customFieldsApi';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';

export const useCustomFieldQuery = () => {
  const customFieldsMigrated = useHasFeatureFlag('CustomFieldsMigratedToProductsView');

  return useQuery({
    queryKey: customFieldsQuery.all,
    queryFn: async () => {
      const fields = await fetchCustomFields(customFieldsMigrated);
      return fields;
    },
    gcTime: 0,
  });
};
