import { useEffect, useState } from 'react';
import {
  AssignedCustomField,
  CustomFieldValue,
} from '../SimInventoryApi/simInventoryApi.interface';
import { getAssignedFields } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { useCustomFieldFromQueryCache } from 'admin/customFields/hooks/useCustomFieldFromQueryCache';

export const useSimCustomFields = (customFields?: CustomFieldValue[]) => {
  const { data: customFieldsData } = useCustomFieldFromQueryCache();

  const clearCustomFields = () => {
    setAssignedCustomFields(getAssignedFields(customFields || [], customFieldsData || []));
  };

  const [assignedCustomFields, setAssignedCustomFields] = useState<AssignedCustomField[]>([]);

  const originalCustomFields = getAssignedFields(customFields || [], customFieldsData || []);

  useEffect(() => {
    if (customFieldsData) {
      setAssignedCustomFields(getAssignedFields(customFields || [], customFieldsData || []));
    }
  }, [customFieldsData, customFields]);

  return {
    customFieldsData,
    assignedCustomFields,
    originalCustomFields,
    setAssignedCustomFields,
    clearCustomFields,
  };
};
