import { GridCell, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TableTooltip } from '../../../Tooltip/TableTooltip';

export type CellConfiguration = Record<string, any>;

type CellLoaderProps = GridRenderCellParams & {
  cellConfiguration: CellConfiguration;
  cellProps?: any;
};

export const CellLoader = ({ cellConfiguration, cellProps, value, ...props }: CellLoaderProps) => {
  const Component = cellConfiguration?.[props.field] || cellConfiguration?.['default'];
  const componentProps = cellProps?.[props.field] || {};
  if (!Component) {
    return (
      <TableTooltip value={value}>
        <GridCell {...props} {...componentProps} value={value} />
      </TableTooltip>
    );
  }

  //@ts-ignore
  const subField = props.colDef.subField?.field;

  //@ts-ignore
  const subFieldValueGetter = props.colDef.subField?.valueGetter;

  let subValue = props.row[subField];
  if (subFieldValueGetter) {
    subValue = subFieldValueGetter(subValue);
  }

  return <Component {...props} {...componentProps} value={value} subValue={subValue} />;
};
