import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { rsimFiltersAtom } from 'atoms/filters/rsim-products';
import { activePageAtom, PageName, pageTabAtom } from 'atoms/pages';
import { useAtomValue } from 'jotai';
import React, { useMemo } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { mapRSimFiltersToURLSearchParams } from 'rSimInventory/Filters/utils/filtersToURLSearcParamsMapper';

export type NavigationLinkProps = {
  to: To;
  'data-testid'?: string;
  label: string;
  icon?: React.ReactNode;
  page: PageName;
  defaultActiveTabName?: string;
};

const ListItemButton = styled(MuiListItemButton)(({ selected, theme }) => {
  return {
    borderRight: selected ? '4px solid ' : '',
    marginLeft: theme.spacing(1),
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    paddingLeft: theme.spacing(2),
    borderColor: theme.palette.grey[500],
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  };
});

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => {
  return {
    minWidth: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  };
});

export const NavigationLink = ({
  page,
  label,
  icon,
  defaultActiveTabName,
  ...props
}: NavigationLinkProps) => {
  const activePage = useAtomValue(activePageAtom);
  const navigationPage = useAtomValue(pageTabAtom(page));

  const activeTabName = navigationPage.activeTab;
  const tabs = navigationPage.tabs;

  const activeTab = defaultActiveTabName
    ? tabs.find((tab) => tab.name === defaultActiveTabName)
    : tabs.find((tab) => tab.name === activeTabName);

  const navigate = useNavigate();

  const rsimFilters = useAtomValue(rsimFiltersAtom);

  const urlParams = useMemo(() => {
    if (activeTabName === 'RSim') {
      return mapRSimFiltersToURLSearchParams(rsimFilters);
    }
  }, [activeTabName, rsimFilters]);

  return (
    <ListItem
      disablePadding
      {...props}
      onClick={() => {
        if (activeTab?.pathname && urlParams) {
          navigate(`${activeTab?.pathname}?${urlParams.toString()}`);
        } else if (activeTab?.pathname) {
          navigate(`${activeTab?.pathname}`);
        }
      }}
    >
      <ListItemButton selected={activePage === page}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography sx={{ typography: 'h4' }} primary={label} />
      </ListItemButton>
    </ListItem>
  );
};
