import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { Sorting } from 'common/hooks/useSorting';

type SimProductsFiltersWithSorting = SimInventoryFiltersV2 & {
  sorting: { columnName: string; sort: Sorting } | null;
};

type SimProductsFiltersWithSortingAndPagination = SimProductsFiltersWithSorting & {
  page?: number;
  rowsPerPage?: number;
};

export const simProductsQueries = {
  all: ['SimProductsConfigurable'],
  list: ({
    sorting,
    customFields = {},
    tags,
    ...otherFilters
  }: SimProductsFiltersWithSortingAndPagination) => {
    const sortingFilter = sorting || {};

    return [
      ...simProductsQueries.all,
      {
        ...sortingFilter,
        ...customFields,
        tags: tags ? [...tags].sort() : null,
        ...otherFilters,
      },
    ];
  },
  pagination: (filters: SimProductsFiltersWithSorting) => {
    const [allKey, listKey] = simProductsQueries.list(filters);
    return [allKey, 'Pagination', listKey];
  },
  export: (filters: SimProductsFiltersWithSorting) => {
    return [...simProductsQueries.list(filters), 'Export'];
  },
};
