import React, { Dispatch, SetStateAction } from 'react';
import {
  CustomField,
  CustomFieldSelectedOption,
  FIELD_TYPE,
} from 'admin/customFields/entities/customFields.entity';
import { TextFieldWithCounter } from 'common/components/Inputs/TextfieldWithCounter/TextFieldWithCounter';
import { SelectWithSearch } from 'common/components/Inputs/SelectWithSearch/SelectWithSearch';
import {
  addAssignedValue,
  getAssignedOption,
} from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { AssignedCustomField } from '../../../../../simInventory/SimInventoryApi/simInventoryApi.interface';

export type CustomFieldInputProps = {
  field: CustomField;
  fieldIndex: number;
  setChanges: (changes: boolean) => void;
  assignedCustomFields: AssignedCustomField[];
  setAssignedCustomFields: Dispatch<SetStateAction<AssignedCustomField[]>>;
};

export const CustomFieldInput = React.memo(
  ({
    field,
    fieldIndex,
    setChanges,
    assignedCustomFields,
    setAssignedCustomFields,
  }: CustomFieldInputProps) => {
    const assignedOption: CustomFieldSelectedOption | null = getAssignedOption(
      fieldIndex,
      field.selectionOptions || [],
      assignedCustomFields,
    );

    return (
      <>
        {field.dataType === FIELD_TYPE.Text && (
          <TextFieldWithCounter
            data-testid={`customField-${field.label}`}
            name={`customField-text-${field.id}`}
            value={assignedCustomFields[fieldIndex]?.value}
            onChange={(e) => {
              setAssignedCustomFields((prev) =>
                addAssignedValue(fieldIndex, e.target.value, field, prev),
              );
              setChanges(true);
            }}
            maxLength={255}
          />
        )}
        {field.dataType === FIELD_TYPE.Dropdown && (
          <SelectWithSearch
            data-testid={`customField-${field.label}`}
            value={assignedOption ? { id: assignedOption.id!, label: assignedOption.label } : null}
            onChange={(value) => {
              setAssignedCustomFields((prev) =>
                addAssignedValue(fieldIndex, value ? value.id.toString() : null, field, prev),
              );
              setChanges(true);
            }}
            options={field.selectionOptions.map((option) => ({
              id: option.id!,
              label: option.label,
            }))}
          />
        )}
      </>
    );
  },
);
