import { authFetch } from 'auth/utils';
import { ErrorCodes } from 'common/errorCodes';

export const provisionRsim = async (connectionIds: string[], activate: boolean) => {
  const body = {
    connectionIds,
    activate,
  };

  const response = await authFetch(`${process.env.REACT_APP_RSIM_PRODUCTS_API}/provisioning`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok && response.status.toString() === ErrorCodes.BadRequest) {
    throw new ActivationRequestFailedError();
  }

  if (!response.ok && response.status.toString() === ErrorCodes.NotFound) {
    throw new NotFoundError();
  }
};

export class ActivationRequestFailedError extends Error {
  constructor() {
    super(ErrorCodes.BadRequest);
    Object.setPrototypeOf(this, ActivationRequestFailedError.prototype);
  }
}

export class NotFoundError extends Error {
  constructor() {
    super(ErrorCodes.NotFound);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}
