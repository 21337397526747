import { TableLayoutWithPaginationWithoutScrollArea } from 'common/components/Table/TableLayoutWithPaginationWithoutScrollArea';
import { NoDataMessage } from '../../common/components/Table/NoDataMessage';
import React from 'react';
import { TableSkeleton } from '../../common/components/Table/TableSkeleton';
import { SuspenseLoadingView } from '../../common/components/SuspenseLoadingView';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextCell } from '../../common/components/Table/Cell/TextCell';
import { useTranslation } from 'react-i18next';
import { useAuditTrail } from '../hooks/useAuditTrail';
import { Option } from '../../common/components/Inputs/SelectWithSearch/SelectWithSearch';
import { DateRangeValuePiece } from '../../common/components/Inputs/DateRangePickerMui/DateRangePickerMui';
import { isDateValid } from '../../common/components/Inputs/DateRangePickerMui/DateRangePickerMui.util';

export const AuditTrailTable = ({
  dateRange,
  iccid,
  selectedUsername,
  selectedField,
}: {
  iccid: string;
  dateRange: [DateRangeValuePiece, DateRangeValuePiece];
  selectedUsername: Option | null;
  selectedField: string[];
}) => {
  const { t } = useTranslation();

  const { data: dataAuditTrail, pagination } = useAuditTrail(
    iccid!,
    selectedUsername?.label as string,
    dateRange && dateRange[0] && isDateValid(dateRange[0])
      ? new Date(dateRange[0]).toISOString()
      : null,
    dateRange && dateRange[1] && isDateValid(dateRange[1])
      ? new Date(dateRange[1]).toISOString()
      : null,
    selectedField,
  );

  const columns: GridColDef[] = [
    {
      field: 'propertyName',
      renderHeader: () => (
        <Typography
          data-testid="actions-header"
          variant="text3"
          color="text.primary"
          sx={{ paddingLeft: '14px' }}
        >
          {t('auditTrail.tableHeaders.field')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TextCell
            text={params.row.propertyName}
            testId="auditTrailFieldsCell"
            sx={{ paddingLeft: '14px' }}
          />
        );
      },
      width: 140,
      minWidth: 70,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'oldValue',
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('auditTrail.tableHeaders.changedFrom')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TextCell text={params.row.oldValue} testId="auditTrailChangedFromCell" />;
      },
      width: 150,
      minWidth: 70,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'newValue',
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('auditTrail.tableHeaders.changedTo')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TextCell text={params.row.newValue} testId="auditTrailChangedToCell" />;
      },
      width: 150,
      minWidth: 70,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'createdAt',
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('auditTrail.tableHeaders.effectiveTime')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TextCell
            text={new Date(params.row.createdAt).toLocaleString()}
            testId="auditTrailEffectiveTimeCell"
          />
        );
      },
      width: 150,
      minWidth: 70,
      maxWidth: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'sourceName',
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('auditTrail.tableHeaders.source')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TextCell text={params.row.sourceName} testId="auditTrailSourceCell" />;
      },
      width: 180,
      minWidth: 70,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'changedBy',
      renderHeader: () => (
        <Typography data-testid="actions-header" variant="text3" color="text.primary">
          {t('auditTrail.tableHeaders.userName')}
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams) => {
        return <TextCell text={params.row.changedBy} testId="auditTrailUserNameCell" />;
      },
      width: 180,
      minWidth: 70,
      maxWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  return (
    <TableLayoutWithPaginationWithoutScrollArea
      noItemsMessage={<NoDataMessage searchText={'SearchText'} prevSearchText={'PrevSearchText'} />}
      page={pagination.page}
      rowsPerPage={pagination.perPage}
      onPageChange={pagination.onPageChange}
      onRowsPerPageChange={pagination.onRowsPerPageChange}
      totalNumberOfItems={pagination.totalNumberOfItems}
      showLastButton
      table={
        <SuspenseLoadingView
          loadFallback={
            <TableSkeleton showStartSkeleton showEndSkeleton numberOfRows={20} pagination={false} />
          }
          queryKey={['AuditTrail']}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <DataGridPro
              disableVirtualization
              sx={{ border: 'none', backgroundColor: 'white' }}
              getRowId={() => Math.random()}
              rows={dataAuditTrail || []}
              columns={columns}
              hideFooter
              localeText={{ noRowsLabel: '' }}
              data-testid="auditTrail table"
            />
          </Box>
        </SuspenseLoadingView>
      }
    />
  );
};
