import Typography from '@mui/material/Typography';
import { TableTooltip } from 'common/components/Tooltip/TableTooltip';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const TypographyStyled = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}) as typeof Typography;

const BoxStyled = styled(Box)({
  overflow: 'hidden',
});

export const TextCell = ({ value, subValue }: any) => {
  return (
    <BoxStyled>
      {value !== null && value !== undefined ? (
        <TableTooltip value={value}>
          <TypographyStyled
            variant="text1"
            color="text.primary"
            component="div"
            data-testid="primaryText"
          >
            {value}
          </TypographyStyled>
        </TableTooltip>
      ) : (
        <TypographyStyled data-testid="primaryText" />
      )}
      {subValue !== null && subValue !== undefined ? (
        <TableTooltip value={subValue}>
          <TypographyStyled
            variant="text5"
            color="text.secondary"
            component="div"
            data-testid="secondaryText"
          >
            {subValue}
          </TypographyStyled>
        </TableTooltip>
      ) : (
        <TypographyStyled data-testid="secondaryText" />
      )}
    </BoxStyled>
  );
};
