import {
  ProvisioningSourceFilter,
  ProvisioningStatusFilter,
} from 'bulkProvisioning/BulkProvisioning.interface';

type Filters = {
  fileName: string;
  orderNumber: string;
  uploadDateFrom: Date | null;
  uploadDateTo: Date | null;
  status: ProvisioningStatusFilter;
  source: ProvisioningSourceFilter;
  page: number;
  rowsPerPage: number;
};

export const provisioningQueries = {
  all: ['provisioning'] as const,
  inventory: ({ uploadDateFrom, uploadDateTo, ...other }: Filters) => {
    return [
      ...provisioningQueries.all,
      {
        uploadDateFrom: uploadDateFrom ? uploadDateFrom.toISOString() : '',
        uploadDateTo: uploadDateTo ? uploadDateTo.toISOString() : '',
        ...other,
      },
    ];
  },
  downloadTemplate: ['provisioning', 'downloadTemplate'] as const,
};
