import { customFieldsFilterAtom } from 'atoms/filters/sim-products';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useEnabledCustomFieldQuery } from 'admin/customFields/hooks/useEnabledCustomFieldQuery';

export const useCustomFieldsFiltersInitialization = () => {
  const { isFetched, data } = useEnabledCustomFieldQuery();

  const setCustomFieldsFilters = useSetAtom(customFieldsFilterAtom);

  useEffect(() => {
    if (isFetched && data?.length && data?.length > 0) {
      setCustomFieldsFilters((currentFilters: any) => {
        const newCustomFilters = data.reduce((filters, customFields) => {
          let currentValue = currentFilters[customFields.id.toString()];
          if (!currentValue) {
            currentValue = customFields.dataType === 'Dropdown' ? [] : '';
          }

          return {
            ...filters,
            [customFields.id.toString()]: currentValue,
          };
        }, {} as Record<string, string | string[]>);

        return newCustomFilters;
      });
    }
  }, [isFetched, data]);
};
