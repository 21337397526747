import { useAtom, WritableAtom } from 'jotai';
import React, { SetStateAction } from 'react';
import { MnoStatusFilter } from 'simInventory/models/SimProfile.model';
import { FilterChip } from './FilterChip';
import { MNO_STATUS_VALUE_LABEL_MAP } from 'simInventory/SimInventoryApi/simInventoryApi.constants';
import { useTranslation } from 'react-i18next';

type MnosChipsProps = {
  mnoStatusAtom: WritableAtom<MnoStatusFilter[], [SetStateAction<MnoStatusFilter[]>], void>;
};

export const MnoStatusChips = ({ mnoStatusAtom }: MnosChipsProps) => {
  const { t } = useTranslation();
  const [mnoStatuses, setMNOStatus] = useAtom(mnoStatusAtom);

  const onClear = (status: MnoStatusFilter) => {
    setMNOStatus((currentMNOStatus) =>
      currentMNOStatus.filter((mnoStatus) => mnoStatus !== status),
    );
  };

  if (mnoStatuses.length === 0) {
    return null;
  }

  return (
    <>
      {mnoStatuses.map((status) => {
        return (
          <React.Fragment key={status}>
            <FilterChip
              text={`${t('simInventory.mnoStatus')}: ${MNO_STATUS_VALUE_LABEL_MAP[status]}`}
              onClose={() => {
                onClear(status);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
