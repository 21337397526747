import { useQuery } from '@tanstack/react-query';
import { CustomField } from '../entities/customFields.entity';
import { customFieldsQuery } from '../utils/queries';

export const useCustomFieldFromQueryCache = () => {
  return useQuery<CustomField[]>({
    queryKey: customFieldsQuery.all,
    enabled: false, //It will use previous fetched custom fields
    initialData: [],
  });
};
