import { parseResponseDate, parseResponseDateAndTime } from 'common/utils/parseResponseDate';
import {
  Localization,
  MNOStatus,
  MNOStatusSchema,
  SimProfileDTO,
} from './simInventoryApi.interface';
import { MNO_STATUS_VALUE_LABEL_MAP } from './simInventoryApi.constants';
import { mapSessionStatusToStringSessionStatus } from '../utils/sessionStatus';
import { z, ZodTypeAny } from 'zod';
import { Tag } from 'tags/services/tagsApi.types';
import { NormilizedAssignedTags } from 'tags/utils';
import { ACTIVATION_ACTIONS } from 'rSimInventory/models/RSimProfile.model';

type ZodValidStatusesUnionType = z.ZodUnion<[ZodTypeAny, ...ZodTypeAny[]]>;

const parseStatusToLabelOrReturnDefault = <T extends ZodValidStatusesUnionType>(
  status: z.infer<T> | null | undefined,
  mapper: Record<z.infer<T>, string>,
  schema: T,
  defaultValue: string = '',
) => {
  const validation = schema.safeParse(status);
  if (validation.success) {
    return mapper[validation.data as keyof typeof mapper];
  }

  return defaultValue;
};

const parseMNOStatusToDisplayValue = (mnoStatus: MNOStatus | null | undefined) =>
  parseStatusToLabelOrReturnDefault(mnoStatus, MNO_STATUS_VALUE_LABEL_MAP, MNOStatusSchema);

const parseLocalization = (localization: Localization | null | undefined) => {
  return localization?.value || '';
};

type ExternalOptionalData = {
  normalizedTagsData?: NormilizedAssignedTags;
};

type TagsData = {
  items: Tag[];
  hasMore: boolean;
};

export const mapSimProfileDTO = (
  profile: SimProfileDTO,
  externalOptionalData?: ExternalOptionalData,
) => {
  const {
    accountRef,
    mnoStatus,
    mnoStatusEntryTime,
    simType,
    liveDataUsage,
    inMinuteUsage,
    inTextUsage,
    outMinuteUsage,
    outTextUsage,
    sessionStatus,
    sessionStartTime,
    sessionEndTime,
    sessionStatusLastUpdated,
    orderNumber,
    connectionId,
    apn,
    localization,
    rasClient,
    framedProtocol,
    nasIpAddress,
    serviceType,
    userName,
    cumulativeInputUsage,
    cumulativeOutputUsage,
    productCode,
    productType,
    activationAction,
    currentIpAddress,
    ...rest
  } = profile;

  let tags: TagsData | undefined;

  if (
    externalOptionalData?.normalizedTagsData &&
    externalOptionalData.normalizedTagsData[rest.iccid]
  ) {
    const { items, hasMore } = externalOptionalData.normalizedTagsData[rest.iccid];
    tags = {
      items: items,
      hasMore: hasMore,
    };
  }

  let cumulativeTotalUsage = undefined;
  if (cumulativeInputUsage !== undefined || cumulativeOutputUsage !== undefined) {
    const isUsageNotNull = cumulativeInputUsage !== null || cumulativeOutputUsage !== null;
    cumulativeTotalUsage = isUsageNotNull
      ? (cumulativeInputUsage || 0) + (cumulativeOutputUsage || 0)
      : null;
  }

  return {
    ...rest,
    ...(accountRef !== undefined && { accountRef: accountRef?.toString() || '' }),
    ...(mnoStatusEntryTime !== undefined && {
      mnoStatusEntryTime: parseResponseDate(mnoStatusEntryTime),
    }),
    ...(mnoStatus !== undefined && {
      mnoStatus: parseMNOStatusToDisplayValue(mnoStatus),
    }),
    ...(simType !== undefined && { simType: simType || '' }),
    ...(sessionStatus !== undefined && {
      sessionStatus: mapSessionStatusToStringSessionStatus(sessionStatus),
    }),
    ...(liveDataUsage !== undefined && { liveDataUsage: liveDataUsage?.toString() || '' }),
    ...(inTextUsage !== undefined && { inTextUsage: inTextUsage?.toString() || '' }),
    ...(outTextUsage !== undefined && { outTextUsage: outTextUsage?.toString() || '' }),
    ...(inMinuteUsage !== undefined && { inMinuteUsage: inMinuteUsage?.toString() || '' }),
    ...(outMinuteUsage !== undefined && { outMinuteUsage: outMinuteUsage?.toString() || '' }),
    ...(sessionStartTime !== undefined && {
      sessionStartTime: parseResponseDateAndTime(sessionStartTime),
    }),
    ...(sessionEndTime !== undefined && {
      sessionEndTime: parseResponseDateAndTime(sessionEndTime),
    }),
    ...(sessionStatusLastUpdated !== undefined && {
      sessionStatusLastUpdated: parseResponseDateAndTime(sessionStatusLastUpdated),
    }),
    ...(orderNumber !== undefined && { orderNumber: orderNumber || '' }),
    ...(connectionId !== undefined && { connectionId: connectionId || '' }),
    ...(apn !== undefined && { apn: apn || '' }),
    ...(localization !== undefined && { localization: parseLocalization(localization) || '' }),
    ...(rasClient !== undefined && { rasClient: rasClient || '' }),
    ...(framedProtocol !== undefined && { framedProtocol: framedProtocol || '' }),
    ...(nasIpAddress !== undefined && { nasIpAddress: nasIpAddress || '' }),
    ...(serviceType !== undefined && { serviceType: serviceType || '' }),
    ...(userName !== undefined && { userName: userName || '' }),
    ...(cumulativeInputUsage !== undefined && {
      cumulativeInputUsage: cumulativeInputUsage?.toFixed(2) || '',
    }),
    ...(cumulativeOutputUsage !== undefined && {
      cumulativeOutputUsage: cumulativeOutputUsage?.toFixed(2) || '',
    }),
    ...(cumulativeTotalUsage !== undefined && {
      cumulativeTotalUsage: cumulativeTotalUsage?.toFixed(2) || '',
    }),
    ...(productCode !== undefined && { productCode: productCode || '' }),
    ...(productType !== undefined && { productType: productType || '' }),
    ...(currentIpAddress !== undefined && { currentIpAddress: currentIpAddress || '' }),
    ...(activationAction !== undefined && {
      activationAction: activationAction?.toLocaleLowerCase() || ACTIVATION_ACTIONS.NONE,
    }),
    tags: tags as TagsData,
    rawData: {
      sessionStatusLastUpdated,
    },
  };
};
