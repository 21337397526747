import { MNO_STATUS } from './simInventoryApi.constants';
import { mapSimProfileDTO } from './simInventoryApi.utils';

import { z } from 'zod';
import { Tag } from 'tags/services/tagsApi.types';
import { SessionStatus } from '../utils/sessionStatus';
import { CustomFieldType } from '../../admin/customFields/entities/customFields.entity';
import { ObjectValues } from 'common/tsHelpers';
import { ActivationAction } from 'rSimInventory/models/RSimProfile.model';

export const MNOStatusSchema = z.union([
  z.literal(MNO_STATUS.UNRECOGNIZED),
  z.literal(MNO_STATUS.TESTREADY),
  z.literal(MNO_STATUS.ACTIVEREADY),
  z.literal(MNO_STATUS.ACTIVE),
  z.literal(MNO_STATUS.DEACTIVATED),
  z.literal(MNO_STATUS.RETIRED),
]);

export type MNOStatus = z.infer<typeof MNOStatusSchema>;

export const BILLING_STATUS = {
  NON_BILLED: 0,
  BILLED: 1,
} as const;

export type BillingStatus = ObjectValues<typeof BILLING_STATUS> | null;

export type SimProfileDTO = {
  iccid: string;
  imsi?: string | null;
  msisdn?: string | null;
  euicc?: string | null;
  imei?: string | null;
  organisation?: string | null;
  mobileNetworkOperator?: string | null;
  customerCommsPlan?: string | null;
  simType?: string;

  mnoStatus?: MNOStatus | null;
  mnoStatusEntryTime?: string | null;
  sessionStatus?: SessionStatus | null;
  sessionStartTime?: string | null;
  sessionEndTime?: string | null;
  sessionStatusLastUpdated?: string | null;
  apn?: string | null;
  localization?: Localization | null;
  liveDataUsage?: number | null;
  inMinuteUsage?: number | null;
  outMinuteUsage?: number | null;
  inTextUsage?: number | null;
  outTextUsage?: number | null;
  orderNumber?: string | null;
  connectionId?: string | null;
  customerReference?: string | null;
  managementIpAddress?: string | null;
  currentIpAddress?: string | null;
  accountRef?: number | null;
  accountName?: string | null;
  commsPlan?: string | null;
  mnoRatePlan?: string | null;
  accountIdentifier?: string | null;
  rasClient?: string | null;
  framedProtocol?: string | null;
  nasIpAddress?: string | null;
  serviceType?: string | null;
  userName?: string | null;
  timeZone?: string | null;
  cumulativeInputUsage?: number | null;
  cumulativeOutputUsage?: number | null;
  eventTimestamp?: string | null;
  ratType?: string | null;
  tags?: Tag[];
  customFields?: CustomFieldValue[];
  activeConfiguration?: string | null;
  productCode?: string | null;
  productType?: string | null;
  billingStatus?: BillingStatus | null;
  activationAction?: ActivationAction | null;
};

export type CustomFieldValue = {
  label: string;
  dataType: CustomFieldType;
  selectionOption?: string;
  textValue?: string;
};

export type AssignedCustomField = {
  label: string;
  value: string;
};

export type ValuesTypes = keyof SimProfileDTO;

export type SimInventoryResponse = {
  headers: SimInventoryHeaderResponse[];
  items: SimProfileDTO[];
  totalCount: number;
  totalPages: number;
};

export type SimInventoryResponsePagination = {
  totalCount: number;
  totalPages: number;
};

export type SimProfile = ReturnType<typeof mapSimProfileDTO>;

export type SimInventoryData = {
  items: SimProfile[];
  headers?: SimInventoryHeaderResponse[];
  totalNumberOfItems?: number;
  numberOfPages?: number;
};

export type SimInventoryHeaderResponse = {
  id: string;
  types: ValuesTypes[];
};

export type MNOStatusLabel =
  | 'Unrecognized'
  | 'Test Ready'
  | 'Active Ready'
  | 'Active'
  | 'Deactivated'
  | 'Retired';

export type ValidationError = {
  code: string;
  message: string;
};
export type ErrorResponse = {
  validationErrors: Record<string, ValidationError[]>;
};
export type Localization = {
  value: string;
};
