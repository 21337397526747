import { MNOStatus, MNOStatusLabel } from './simInventoryApi.interface';

import { i18 } from 'i18n/i18n';
import { SessionStatus } from '../utils/sessionStatus';

export const MNO_STATUS = {
  UNRECOGNIZED: 0,
  TESTREADY: 1,
  ACTIVEREADY: 2,
  ACTIVE: 3,
  DEACTIVATED: 4,
  RETIRED: 5,
} as const;

export const MNO_STATUS_VALUE_LABEL_MAP: Record<MNOStatus, MNOStatusLabel> = {
  [MNO_STATUS.UNRECOGNIZED]: 'Unrecognized',
  [MNO_STATUS.TESTREADY]: 'Test Ready',
  [MNO_STATUS.ACTIVEREADY]: 'Active Ready',
  [MNO_STATUS.ACTIVE]: 'Active',
  [MNO_STATUS.DEACTIVATED]: 'Deactivated',
  [MNO_STATUS.RETIRED]: 'Retired',
};

export const SESSION_STATUS_VALUE_LABEL_MAP: Record<SessionStatus, string> = {
  Unknown: i18.t('simInventory.unknown'),
  InSession: i18.t('simInventory.inSession'),
  Disconnected: i18.t('simInventory.disconnected'),
};
