import { authFetch } from '../../auth/utils';
import { saveAs } from 'file-saver';

export const fetchAuditTrailByIccid = async (
  iccid: string,
  page: number,
  perPage: number,
  userName?: string,
  dateFrom?: string | null | undefined,
  dateTo?: string | null | undefined,
  fields?: string[],
) => {
  const urlQuery = new URLSearchParams({
    Iccid: iccid?.toString(),
    PageSize: perPage?.toString(),
    PageNumber: (page + 1)?.toString(),
    ...(userName ? { Username: userName?.toString() } : {}),
    ...(dateFrom ? { DateFrom: dateFrom } : {}),
    ...(dateTo ? { DateTo: dateTo } : {}),
  });

  const fieldsString =
    !!fields && fields.length > 0 ? fields.map((field) => `PropertyNames=${field}`).join('&') : '';

  const auditTrailByIccidResponse = await authFetch(
    `${process.env.REACT_APP_AUDIT_TRAIL_SIM_PROFILE_API}?${urlQuery.toString()}${
      fieldsString ? '&' + fieldsString : ''
    }`,
    {
      headers: {
        accept: 'application/json',
      },
    },
  );

  return await auditTrailByIccidResponse.json();
};

export const fetchUsersAuditTrail = async (userName?: string, iccid?: string) => {
  const urlQuery = new URLSearchParams({
    ...(userName
      ? {
          Search: userName.toString(),
        }
      : {}),
    ...(iccid ? { Iccid: iccid } : {}),
  });

  const usersAuditTrailResponse = await authFetch(
    `${process.env.REACT_APP_AUDIT_TRAIL_SIM_PROFILE_API}/users?${urlQuery.toString()}`,
  );

  return await usersAuditTrailResponse.json();
};

export const fetchFieldsAuditTrail = async () => {
  const eventTypesAuditTrailResponse = await authFetch(
    `${process.env.REACT_APP_AUDIT_TRAIL_SIM_PROFILE_API}/fields`,
  );

  return await eventTypesAuditTrailResponse.json();
};

export const downloadAuditTrail = async (
  iccid: string,
  userName?: string,
  dateFrom?: string | null | undefined,
  dateTo?: string | null | undefined,
  fields?: string[],
) => {
  const body = {
    iccid: iccid,
    ...(userName ? { username: userName?.toString() } : {}),
    ...(dateFrom ? { DateFrom: dateFrom } : {}),
    ...(dateTo ? { DateTo: dateTo } : {}),
    ...(fields && fields.length > 0 ? { propertyNames: fields } : {}),
    timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const response = await downloadFile(
    `${process.env.REACT_APP_AUDIT_TRAIL_REPORTING_API}/audit-trails`,
    body,
  );

  if (!response.ok) {
    await response.text().then((text) => {
      throw new Error(text);
    });
  }
};

const downloadFile = async (
  filePath: string,
  body: {
    iccid: string;
    username?: string;
    dateFrom?: string;
    dateTo?: string;
    propertyNames?: string[];
  },
) => {
  const getFileNameFromContentDispositionHeader = (headerValue: string | null) =>
    headerValue
      ?.split(';')
      ?.find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim()
      .replace(/"/g, '');

  // @ts-ignore
  const fileResponse = await authFetch(filePath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!fileResponse.ok) {
    return fileResponse;
  }

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);

  return fileResponse;
};
