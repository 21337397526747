export const SIM_INVENOTRY_PERMISSIONS = {
  ViewSimListAndDetails: 'viewSimList',
  ViewSimDetails: 'viewSimDetails',
  ViewTags: 'viewTags',
  EditTags: 'editTags',
  Export: 'export',
  ExportPoolingLogs: 'exportPoolingLogs',
  SimulateOutage: 'simulateOutage',
  RestoreOTAConfiguration: 'restoreOtaConfiguration',
  ViewOtaConfigurationsDetails: 'viewOtaConfigurationsDetails',
  BulkProvisioning: 'bulkProvisioning',
  ViewCustomFields: 'viewCustomFields',
  HasInternalDataAccess: 'hasInternalDataAccess',
  ViewRsim: 'viewRsim',
  EditCustomFields: 'editCustomFields',
  ImportRsims: 'importRsims',
  RSimProductProvisioning: 'rsimProductProvisioning',
  SimProductProvisioning: 'simProductProvisioning',
} as const;

export type SimInventoryPermissions =
  (typeof SIM_INVENOTRY_PERMISSIONS)[keyof typeof SIM_INVENOTRY_PERMISSIONS];

export const BULK_PROVISIONING_PERMISSIONS = {
  DownloadReport: 'downloadReport',
  ViewTableAndDetails: 'viewTableAndDetails',
  UploadFile: 'uploadFile',
  SetSimStatus: 'setSimStatus',
} as const;

export type BulkProvisioningPermissions =
  (typeof BULK_PROVISIONING_PERMISSIONS)[keyof typeof BULK_PROVISIONING_PERMISSIONS];

export const AUDIT_TRAILS_PERMISSIONS = {
  viewAuditTrailList: 'viewAuditTrailList',
  export: 'export',
} as const;

export type AuditTrailsPermissions =
  (typeof AUDIT_TRAILS_PERMISSIONS)[keyof typeof AUDIT_TRAILS_PERMISSIONS];
