import { parseISO } from 'date-fns';
import { saveAs } from 'file-saver';

import {
  ProvisioningRequestDto,
  ProvisioningRequestsDto,
} from './bulkProvisioningApiOLD.interface';
import { authFetch } from 'auth/utils';
import {
  ProvisioningSourceFilter,
  ProvisioningStatusFilter,
} from 'bulkProvisioning/BulkProvisioningOLD.interface';
import {
  PROVISIONING_SOURCE,
  PROVISIONING_STATUS,
  PROVISIONING_STATUS_TO_STATUS_LABEL_MAP,
} from 'bulkProvisioning/BulkProvisioningOLD.constants';

export const templates = ['MasterTemplate', 'BulkProvisioning', 'UnitDataAdjustment'] as const;
export type TemplateType = (typeof templates)[number];
export const isTemplateType = (x: any): x is TemplateType => templates.includes(x);

export const fetchBulkProvisioningJobs = async (
  page: number,
  jobsPerRow: number,
  status: ProvisioningStatusFilter,
  source: ProvisioningSourceFilter,
  uploadDateFrom: Date | null,
  uploadDateTo: Date | null,
  fileName: string,
  orderNumber: string,
  signal?: AbortSignal,
) => {
  const urlQuery = new URLSearchParams({
    PageNumber: (page + 1).toString(),
    PageSize: jobsPerRow.toString(),
  });

  urlQuery.append('FileName', fileName);

  if (status !== '') {
    urlQuery.append('Statuses', [status].toString());
  }

  if (source !== '') {
    urlQuery.append('Sources', [source].toString());
  }

  if (uploadDateFrom) {
    urlQuery.append('UploadDateFrom', uploadDateFrom.toISOString());
  }

  if (uploadDateTo) {
    urlQuery.append('UploadDateTo', uploadDateTo.toISOString());
  }

  if (orderNumber) {
    urlQuery.append('OrderNumber', orderNumber);
  }

  const bulkProvisioningJobsResponse = await authFetch(
    `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}?` + urlQuery.toString(),
    { signal },
  );

  if (!bulkProvisioningJobsResponse.ok) {
    throw new Error(bulkProvisioningJobsResponse.status.toString());
  }

  const bulkProvisioningJobsData: ProvisioningRequestsDto =
    await bulkProvisioningJobsResponse.json();

  return {
    items: bulkProvisioningJobsData.items.map(
      ({
        id,
        status,
        fileName,
        createdAt,
        modifiedAt,
        simStatusesCount: { failed, inProgress, succeed },
        source,
        allChunksReceived,
        errorSummaryFileName,
        orderNumber,
        uploaderName,
      }) => ({
        id,
        status: PROVISIONING_STATUS_TO_STATUS_LABEL_MAP[status],
        numberOfCards: allChunksReceived ? failed + inProgress + succeed : 0,
        numberOfSuccessCards: succeed,
        numberOfFailedCards: failed,
        fileName,
        uploadAt: parseISO(createdAt),
        uploadFinishedAt:
          status === PROVISIONING_STATUS.PROCESSING_SUCCEED ? parseISO(modifiedAt) : null,
        source: source === PROVISIONING_SOURCE.MANUAL ? 'Manual' : 'Automatic',
        errorSummaryFileName: errorSummaryFileName,
        orderNumber,
        uploadBy: uploaderName,
      }),
    ),
    numberOfPages: bulkProvisioningJobsData.totalPages,
    totalNumberOfItems: bulkProvisioningJobsData.totalCount,
  };
};

export const uploadBulkProvisioningFile = async (file: File) => {
  const data = new FormData();
  data.append('file', file, file.name);
  const response = await authFetch(
    `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}`,
    {
      method: 'POST',
      body: data,
    },
  );

  if (!response.ok) {
    await response.text().then((text) => {
      throw new Error(text);
    });
  }
};

export const fetchBulkProvisioningJob = async (id: string, signal?: AbortSignal) => {
  const bulkProvisioningJobsResponse = await authFetch(
    `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}/${id}`,
    { signal },
  );

  const bulkProvisioningJobData: ProvisioningRequestDto = await bulkProvisioningJobsResponse.json();

  return {
    uploadFinishedAt:
      bulkProvisioningJobData.status === PROVISIONING_STATUS.PROCESSING_SUCCEED
        ? parseISO(bulkProvisioningJobData.modifiedAt)
        : null,
    uploadAt: parseISO(bulkProvisioningJobData.createdAt),
    status: PROVISIONING_STATUS_TO_STATUS_LABEL_MAP[bulkProvisioningJobData.status],
    id: bulkProvisioningJobData.id,
    fileName: bulkProvisioningJobData.fileName,
    numberOfCards: bulkProvisioningJobData.allChunksReceived
      ? bulkProvisioningJobData.simStatusesCount.failed +
        bulkProvisioningJobData.simStatusesCount.inProgress +
        bulkProvisioningJobData.simStatusesCount.succeed
      : 0,
    numberOfSuccessCards: bulkProvisioningJobData.simStatusesCount.succeed,
    numberOfFailedCards: bulkProvisioningJobData.simStatusesCount.failed,
  };
};

const downloadFile = async (filePath: string) => {
  const getFileNameFromContentDispositionHeader = (headerValue: string | null) =>
    headerValue
      ?.split(';')
      ?.find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim()
      .replace(/"/g, '');

  const fileResponse = await authFetch(filePath);

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);
};

const downloadFileWithErrorHandling = async (filePath: string, fileName?: string) => {
  const getFileNameFromContentDispositionHeader = (headerValue: string | null) =>
    headerValue
      ?.split(';')
      ?.find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim()
      .replace(/"/g, '');

  // @ts-ignore
  const fileResponse = await authFetch(filePath);

  if (!fileResponse.ok) {
    return fileResponse;
  }

  const defaultFilename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, fileName ?? defaultFilename);

  return fileResponse;
};

export const downloadErrorSummary = async (id: string) =>
  downloadFile(
    `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}/${id}/error-summary`,
  );

export const downloadBulkProvisioningInputFile = async (id: string) =>
  downloadFile(`${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}/${id}/file`);

export const downloadBulkTemplate = async (type: TemplateType) => {
  const response = await downloadFileWithErrorHandling(
    `${process.env.REACT_APP_BULK_PROVISIONING_PROVISIONING_REQUESTS_API}/templates/${type}`,
    type,
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};
