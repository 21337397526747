import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import {
  fetchSimProductsTableConfiguration,
  revertSimProductsTableConfiguration,
  saveSimProductTableConfiguration,
} from '../../../SimProductsApi/simProductsTableConfigurationApi';

import {
  TABLE_VARIANT,
  TableVariant,
} from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { SimProductsConfigurableKey } from '../../../utils/simProducts';

export const useColumnConfiguration = () => {
  const {
    data: configuration,
    refetch,
    status,
    isRefetching,
  } = useSuspenseQuery({
    queryKey: [SimProductsConfigurableKey],
    queryFn: async () => fetchSimProductsTableConfiguration(true),
    gcTime: 0,
  });

  const queryClient = useQueryClient();
  const { mutate: updateVariant, status: updatingStatus } = useMutation({
    mutationFn: async (variant: TableVariant) => {
      return saveSimProductTableConfiguration({
        variant,
        columnConfigurations:
          variant === TABLE_VARIANT.Flat
            ? configuration!.flatColumnConfigurations
            : configuration!.combinedColumnConfigurations,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SimProductsConfigurableKey] });
    },
  });

  const { mutate: revertToDefault, status: revertUpdatingStatus } = useMutation({
    mutationFn: () => {
      if (configuration?.enabledVariant === undefined) {
        throw new Error('need to fetch configuration first!');
      }

      return revertSimProductsTableConfiguration(configuration.enabledVariant);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [SimProductsConfigurableKey] }),
  });

  const currentColumns =
    configuration?.enabledVariant === TABLE_VARIANT.Flat
      ? configuration.flatColumnConfigurations
      : configuration?.combinedColumnConfigurations;

  let mutationStatus = updatingStatus;
  if (revertUpdatingStatus === 'pending') {
    mutationStatus = revertUpdatingStatus;
  }

  return {
    configuration,
    currentColumns,
    updateVariant,
    revertToDefault,
    refetch,
    status,
    updatingStatus: mutationStatus,
    isRefetching,
  };
};
