import { simulateOutageFilterAtom } from 'atoms/filters/rsim-products';

import ToggleButton from '@mui/material/ToggleButton';
import { ToggleGroup } from 'common/components/Inputs/ToggleGroup/ToggleGroup';
import { useAtom } from 'jotai';
import { SIMULATE_OUTAGE_FILTER } from '../../data/filters.constants';
import { useTranslation } from 'react-i18next';

export const SimulateOutageInput = () => {
  const { t } = useTranslation();
  const [simulateOutage, setSimulateOutage] = useAtom(simulateOutageFilterAtom);

  return (
    <ToggleGroup
      sx={{
        width: '100%',
        justifyContent: 'stretch',
      }}
      value={simulateOutage}
      onChange={(_event, value) => {
        if (value === null) {
          return;
        }

        setSimulateOutage(value);
      }}
    >
      <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.ON}>
        {t('common.on')}
      </ToggleButton>
      <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.ANY}>
        {t('common.any')}
      </ToggleButton>
      <ToggleButton sx={{ flex: 1 }} value={SIMULATE_OUTAGE_FILTER.OFF}>
        {t('common.off')}
      </ToggleButton>
    </ToggleGroup>
  );
};
