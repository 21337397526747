import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  checkIsLargeReport,
  downloadSimProfilesReportWithBackgroundSupport,
} from './api/simProfileReportsApi';
import { useAtomValue } from 'jotai';
import { simFiltersAtom, simSortingAtomWithStorage } from 'atoms/filters/sim';
import { useDebounce } from 'usehooks-ts';
import { getSearchIds } from '../Components/Inputs/SimIdSearchInput.util';
import { simInventoryQueries } from 'simInventory/Inventory/queries/simInventoryQueries';
import { ExportReportButton } from 'common/components/Export/ExportReportButton';

export const SimProfileDownloadReportsExtendedFiltersButtonLargeExport: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const filters = useAtomValue(simFiltersAtom);
  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;
  const debouncedSearchText = useDebounce(searchText || '', 500);
  const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;

  const sorting = useAtomValue(simSortingAtomWithStorage);

  const checkIsSimProfileLargeReport = async () => {
    const largeExport = await checkIsLargeReport({
      searchText: getSearchIds(minLengthText),
      chosenIdType: chosenIdType,
      tags,
      customFields,
      ...otherFilters,
    });

    return largeExport;
  };

  const obtainRaport = () =>
    downloadSimProfilesReportWithBackgroundSupport(
      {
        searchText: getSearchIds(minLengthText),
        chosenIdType: chosenIdType,
        tags,
        customFields,
        ...otherFilters,
      },
      sorting,
    );

  const query = useQuery<{ totalNumberOfItems: number }>({
    queryKey: simInventoryQueries.pagination({
      searchText,
      chosenIdType,
      ...otherFilters,
      tags,
      sorting,
      customFields,
    }),
    enabled: false,
  });

  const numberOfCards = query.data?.totalNumberOfItems || 0;
  return (
    <ExportReportButton
      disabled={disabled}
      numberOfEntries={numberOfCards}
      checkIsLargeReport={checkIsSimProfileLargeReport}
      exportMutation={{
        mutationKey: [
          'sim-inventory',
          'reports',
          'download',
          {
            searchText: getSearchIds(minLengthText),
            chosenIdType: chosenIdType,
            tags,
            customFields,
            ...otherFilters,
          },
          sorting,
        ],
        mutationFn: obtainRaport,
      }}
    />
  );
};
