import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useTranslation } from 'react-i18next';
import { TagContext } from 'tags/TagProvider';
import { SetStateAction, useAtom, WritableAtom } from 'jotai';

type TagsSearchInputProps = {
  tagsFilterAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
  tagsHintsAtom: WritableAtom<any, [any], void>;
};

export const TagsSearchFilter = ({ tagsFilterAtom, tagsHintsAtom }: TagsSearchInputProps) => {
  const { t } = useTranslation();
  const [tags, setTags] = useAtom(tagsFilterAtom);
  const [hints, removeHints] = useAtom(tagsHintsAtom);

  const { type, fetchTags } = useContext(TagContext);
  const { data: tagsData } = useQuery({
    queryKey: type === 'sim' ? ['simTags'] : ['rsimTags'],
    queryFn: async () => {
      const tags = await fetchTags();
      return tags.map((tag) => ({
        ...tag,
        toAdd: false,
      }));
    },
  });

  const tagsHint = hints
    ?.map((tagId: string) => {
      const tag = tagsData?.find((validTag) => validTag.id === tagId);
      return tag;
    })
    .filter((tag: any) => !!tag);

  return (
    <AutocompleteSelect
      testId="tags-multiselect"
      //@ts-ignore
      hints={tagsHint || []}
      values={tags}
      options={tagsData || []}
      onChange={(values) => {
        setTags(values.map((value) => value.id));
      }}
      onRemoveHint={removeHints}
      allowAdding={false}
      placeholder={t('rSimInventory.tagsFilterPlaceholder')}
      chipsVariant="outlined"
    />
  );
};
