import { useAtomValue } from 'jotai';
import {
  LAST_ACTIVE_PROFILE_FILTER,
  LAST_UPDATE_TIME_FILTERS,
  SIMULATE_OUTAGE_FILTER,
} from '../data/filters.constants';
import { rsimFiltersAtom } from 'atoms/filters/rsim-products';
import { isEqual } from 'lodash';

export const useHasActiveFilters = () => {
  const filters = useAtomValue(rsimFiltersAtom);

  return Object.entries(filters).some(([key, value]) => {
    if (key === 'chosenIdType' || key === 'searchText') {
      return false;
    }

    if (
      (key === 'lastUpdateTime' && value === LAST_UPDATE_TIME_FILTERS.NONE) ||
      (key === 'lastActiveProfile' && value === LAST_ACTIVE_PROFILE_FILTER.ANY) ||
      (key === 'simulateOutage' && value === SIMULATE_OUTAGE_FILTER.ANY)
    ) {
      return false;
    }

    if (key === 'tags' && isEqual(value, [])) {
      return false;
    }

    if (key === 'accounts' && isEqual(value, [])) {
      return false;
    }

    return value !== '';
  });
};
