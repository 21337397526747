import { DataGridPro, DataGridProProps, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { simsInventoryTableDataTestId } from 'simInventory/SimInventoryDataTestIds';
import { useCallback } from 'react';
import { gridStyles } from 'common/components/ConfigurableTable/utils/TableStyling.util';
import { ColumnConfiguration } from 'simInventory/SimInventoryApi/simInventoryTableConfigurationApi.interface';
import { useColumnConfigurationActions } from '../hooks/useColumnConfigurationActions';

export const SimProductsDataGrid = (
  props: Omit<DataGridProProps, 'onColumnWidthChange' | 'onColumnOrderChange'> & {
    columnConfiguration: ColumnConfiguration[];
  },
) => {
  const { updateWidthOfColumn, updateVisibilityOfColumns, reorder } =
    useColumnConfigurationActions();

  const onColumnWidthChange = useCallback(
    (params: any) => {
      const matchedColumn = props.columnConfiguration.find(
        (row) => row.name === params.colDef.field,
      );
      if (matchedColumn) {
        updateWidthOfColumn(params.colDef.field, params.width, matchedColumn.isCustomField);
      }
    },
    [updateWidthOfColumn],
  );

  const onColumnOrderChange = useCallback(
    (params: any) => {
      const draggedColumnIndex: number = params.oldIndex;
      const targetIndex: number = params.targetIndex;

      reorder(draggedColumnIndex, targetIndex);
    },
    [reorder],
  );

  const onColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      updateVisibilityOfColumns(model);
    },
    [updateVisibilityOfColumns],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <DataGridPro
        data-testid={simsInventoryTableDataTestId}
        getRowClassName={(params) => `row-${params.row.iccid}`}
        getRowId={(row) => row.iccid || Math.random()}
        sx={{
          backgroundColor: 'white',
          border: 'none',
          width: '100%',
          height: '100%',
          ...gridStyles,
        }}
        columnBufferPx={20000}
        hideFooter={true}
        rowBufferPx={520}
        onColumnWidthChange={onColumnWidthChange}
        onColumnOrderChange={onColumnOrderChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        {...props}
        pinnedColumns={{
          right: ['actions'],
        }}
      />
    </DndProvider>
  );
};
