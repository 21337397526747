import React, { Dispatch, SetStateAction } from 'react';
import { CustomField, FIELD_TYPE } from 'admin/customFields/entities/customFields.entity';
import Grid from '@mui/material/Grid2';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { CustomFieldInput } from './CustomFieldInput';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AssignedCustomField } from 'simInventory/SimInventoryApi/simInventoryApi.interface';

export type CustomFieldsEditProps = {
  setChanges: (changes: boolean) => void;
  customFieldsData?: CustomField[];
  assignedCustomFields: AssignedCustomField[];
  setAssignedCustomFields: Dispatch<SetStateAction<AssignedCustomField[]>>;
};

const TypographyStyled = styled(Typography)({
  marginLeft: '16px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const CustomFieldsEdit = React.memo(
  ({
    setChanges,
    customFieldsData = [],
    assignedCustomFields,
    setAssignedCustomFields,
  }: CustomFieldsEditProps) => {
    return (
      <>
        {customFieldsData?.map((field: CustomField) => {
          const index = assignedCustomFields.findIndex((f) => f.label === field.label);
          return (
            <React.Fragment key={field.id}>
              <Grid
                size={4}
                sx={{
                  display: 'flex',
                  alignItems: field.dataType === FIELD_TYPE.Text ? 'flex-start' : 'center',
                  ...(field.dataType === FIELD_TYPE.Text ? { paddingTop: '10px !important' } : {}),
                }}
              >
                <Tooltip title={field.label}>
                  <TypographyStyled variant="text2" color="text.secondary">
                    {field.label}
                  </TypographyStyled>
                </Tooltip>
              </Grid>
              <Grid size={8}>
                <CustomFieldInput
                  field={field}
                  fieldIndex={index}
                  setChanges={setChanges}
                  assignedCustomFields={assignedCustomFields}
                  setAssignedCustomFields={setAssignedCustomFields}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </>
    );
  },
);
