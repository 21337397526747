import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { Sorting } from 'common/hooks/useSorting';

type SimInventoryFiltersWithSorting = SimInventoryFiltersV2 & {
  sorting: { columnName: string; sort: Sorting } | null;
};

type SimInventoryFiltersWithSortingAndPagination = SimInventoryFiltersWithSorting & {
  page?: number;
  rowsPerPage?: number;
};

export const simInventoryQueries = {
  all: ['SimInventoryConfigurable'],
  list: ({
    searchText,
    sorting,
    customFields = {},
    tags,
    ...otherFilters
  }: SimInventoryFiltersWithSortingAndPagination) => {
    const sortingFilter = sorting || {};

    return [
      ...simInventoryQueries.all,
      {
        searchText: searchText.length < 3 ? '' : searchText,
        ...sortingFilter,
        ...customFields,
        tags: tags ? [...tags].sort() : null,
        ...otherFilters,
      },
    ];
  },
  pagination: (filters: SimInventoryFiltersWithSorting) => {
    return [...simInventoryQueries.list(filters), 'Pagination'];
  },
  export: (filters: SimInventoryFiltersWithSorting) => {
    return [...simInventoryQueries.list(filters), 'Export'];
  },
};
