import { ID_SEARCH_REGEX } from 'common/components/Inputs/IdAdvanceSearch/MultipleIdInput';
import { ID_SEARCH_LIMIT } from './SimIdSearchInput';

export const getSearchIds = (searchText: string) => {
  if (searchText.length < 3) {
    return [''];
  }
  const results = searchText.match(ID_SEARCH_REGEX) || [''];
  return results.slice(0, ID_SEARCH_LIMIT);
};
