import { Chip } from '../Chips/Chip';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { ChipProps as MuiChipProps } from '@mui/material/Chip/Chip';

type FilterAsyncChipProps = {
  onCancel: () => void;
  labelPrefix: string;
  fieldName: string;
  queryFn: QueryFunction;
  queryKey: string[];
  queryEnabled: boolean;
  variant?: MuiChipProps['variant'];
};

export const FilterAsyncChip = ({
  onCancel,
  labelPrefix,
  fieldName,
  queryFn,
  queryKey,
  queryEnabled,
  variant = 'filled',
}: FilterAsyncChipProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [...queryKey],
    queryFn: queryFn,
    enabled: queryEnabled,
  });

  useEffect(() => {
    //@ts-ignore
    if (data?.status === 404) {
      onCancel();
    }
  }, [data]);

  return (
    <>
      {/* @ts-ignore*/}
      {data && (!data.status || data.status !== 404) ? (
        <Chip
          // @ts-ignore
          label={`${labelPrefix}: ${data?.[fieldName]}`}
          size="medium"
          variant={variant}
          onDelete={onCancel}
          isLoading={isLoading}
        />
      ) : null}
    </>
  );
};
