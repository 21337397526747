import { RsimProfilesResponse } from './rSimApi';
import { RSimInventoryItem } from './rSimApi.interface';
import { parseResponseDateAndTime } from 'common/utils/parseResponseDate';
import { NormilizedAssignedTags } from 'tags/utils';
import { TagsData } from 'tags/services/tagsApi.types';
import { ACTIVATION_ACTIONS } from 'rSimInventory/models/RSimProfile.model';

type ExternalOptionalData = {
  normalizedTagsData?: NormilizedAssignedTags;
};

export const mapRSimProfileDTO = (
  rsimProfiles: RsimProfilesResponse,
  externalOptionalData?: ExternalOptionalData,
) => {
  const items: RSimInventoryItem[] = rsimProfiles.map((profile) => {
    let tags;
    if (externalOptionalData?.normalizedTagsData?.[profile.euicc]) {
      const { items, hasMore } = externalOptionalData.normalizedTagsData[profile.euicc];
      tags = {
        items: items,
        hasMore: hasMore,
      } as TagsData;
    }

    return {
      id: profile.euicc,
      accountName: profile.accountName || '',
      accountNumber: profile.accountRef?.toString() || '',
      status: profile.rsimProfileStatus,
      euicc: profile.euicc,
      primaryIccid: profile.primaryIccid,
      secondaryIccid: profile.secondaryIccid,
      dataUsage: profile.cycleTo.dataUsage,
      inMinuteUsage: profile.cycleTo.inMinuteUsage,
      inTextUsage: profile.cycleTo.inTextUsage,
      outMinuteUsage: profile.cycleTo.outMinuteUsage,
      outTextUsage: profile.cycleTo.outTextUsage,
      lastPollingTimestamp: parseResponseDateAndTime(profile.lastAckTimestamp),
      tags,
      appliedOtaConfigurationProfile: profile.appliedOtaConfigurationProfile,
      appliedOtaConfigurationVersion: profile.appliedOtaConfigurationVersion,
      isPendingConfiguration: profile.isPendingConfiguration,
      isCustomConfigurationDetected: profile.isCustomConfigurationDetected,
      lastActiveProfile: profile.lastActiveProfile,
      connectionId: profile.connectionId,
      orderNumber: profile.orderNumber,
      customerReference: profile.customerReference,
      iccidOta: profile.iccidOta,
      activationAction: profile.activationAction?.toLowerCase() || ACTIVATION_ACTIONS.NONE,
      productCode: profile.productCode,
    };
  });

  return items;
};
