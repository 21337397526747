import { isUndefined, pick } from 'lodash';

export type useSectionAvailabilityProps<T extends Record<string, any>> = {
  data: T;
  props: (keyof T)[];
};

export const useSectionAvailability = <T extends Record<string, any>>({
  data,
  props,
}: useSectionAvailabilityProps<T>) => {
  const sectionData = pick(data, props);
  return Object.values(sectionData).some((value) => !isUndefined(value));
};
