import { ValuesType } from 'utility-types';
import { SIM_CARD_IDENTIFIERS } from 'data/sim.constants';
import { RSimIdSearchInput } from 'rSimInventory/Components/Inputs/RSimIdSearchInput';
import { useAtom } from 'jotai';
import { chosenIdTypeFilterAtom, searchTextFilterAtom } from 'atoms/filters/rsim-products';

export const RSIM_INVENTORY_ID_SELECTION = {
  EID: SIM_CARD_IDENTIFIERS.EID,
  ICCID: SIM_CARD_IDENTIFIERS.ICCID,
} as const;

export type RSimId = ValuesType<typeof RSIM_INVENTORY_ID_SELECTION>;

export const RSimIdFiltersSearchInput = () => {
  const [chosenId, setChosenId] = useAtom(chosenIdTypeFilterAtom);
  const [search, setSearch] = useAtom(searchTextFilterAtom);

  return (
    <RSimIdSearchInput
      chosenId={chosenId}
      onChosenIdChange={setChosenId}
      search={search}
      onSearchChange={setSearch}
    />
  );
};
