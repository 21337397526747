import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { checkIsLargeReport, downloadProductReport } from './api/simProductReportsApi';
import { useAtomValue } from 'jotai';
import { ExportReportButton } from 'common/components/Export/ExportReportButton';
import { getSearchIds } from 'simInventory/Components/Inputs/SimIdSearchInput.util';
import { simProductsQueries } from '../Inventory/queries/simProductsQueries';
import {
  simProductsFiltersAtom,
  simProductsSortingAtomWithStorage,
} from 'atoms/filters/sim-products';

export const ProductExportButton: React.FC<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const filters = useAtomValue(simProductsFiltersAtom);
  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;

  const sorting = useAtomValue(simProductsSortingAtomWithStorage);

  const checkIsSimProfileLargeReport = async () => {
    const largeExport = await checkIsLargeReport({
      searchText: searchText.length >= 3 ? getSearchIds(searchText) : [''],
      chosenIdType: chosenIdType,
      tags,
      customFields,
      ...otherFilters,
    });

    return largeExport;
  };

  const obtainRaport = () =>
    downloadProductReport(
      {
        searchText: searchText.length >= 3 ? getSearchIds(searchText) : [''],
        chosenIdType: chosenIdType,
        tags,
        customFields,
        ...otherFilters,
      },
      sorting,
    );

  const { data: pagination } = useQuery<{ totalNumberOfItems: number }>({
    queryKey: simProductsQueries.pagination({
      searchText,
      chosenIdType,
      ...otherFilters,
      tags,
      sorting,
      customFields,
    }),
    enabled: false,
  });

  return (
    <ExportReportButton
      disabled={disabled}
      numberOfEntries={pagination?.totalNumberOfItems || -1}
      checkIsLargeReport={checkIsSimProfileLargeReport}
      exportMutation={{
        mutationKey: [
          'sim-products',
          'reports',
          'download',
          {
            searchText: searchText.length >= 3 ? getSearchIds(searchText) : [''],
            chosenIdType: chosenIdType,
            tags,
            customFields,
            ...otherFilters,
          },
          sorting,
        ],
        mutationFn: obtainRaport,
      }}
    />
  );
};
