import { authFetch } from 'auth/utils';
import { ErrorCodes } from 'common/errorCodes';
import saveAs from 'file-saver';
import {
  MaxSimProfilesCountError,
  MaxSimProfilesValidationError,
  ReportAlreadyScheduledError,
} from 'simInventory/SimInventoryApi/simInventoryApi';
import { SimInventorySearchParamsV2, SimInventorySort } from 'simInventory/models/SimProfile.model';
import { prepareSimInventorySearchBody } from 'simInventory/utils/prepareSimInventorySearchBody';
import { getFileNameFromContentDispositionHeader } from 'common/utils/fileDownload';

export const downloadProductReport = async (
  searchParams: SimInventorySearchParamsV2,
  sort: SimInventorySort | null = null,
) => {
  const body = prepareSimInventorySearchBody(searchParams, sort);

  const fileResponse = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_REPORTING_API}/sim-product`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!fileResponse.ok && fileResponse.status.toString() === ErrorCodes.BadRequest) {
    const { validationErrors } = await fileResponse.json();
    if ('MaxSimProfilesCount' in validationErrors) {
      const maxSimProfilesValidationError = validationErrors
        .MaxSimProfilesCount[0] as MaxSimProfilesValidationError;
      throw new MaxSimProfilesCountError(maxSimProfilesValidationError.state.maxSimProfilesCount);
    }
    if ('ReportAlreadyScheduled' in validationErrors) {
      throw new ReportAlreadyScheduledError();
    }
  }

  if (!fileResponse.ok) {
    throw new Error(fileResponse.statusText);
  }

  if (fileResponse.status === 202) {
    return;
  }

  const filename = getFileNameFromContentDispositionHeader(
    fileResponse.headers.get('Content-Disposition'),
  );

  const blob = await fileResponse.blob();

  saveAs(blob, filename);

  return fileResponse;
};

export const checkIsLargeReport = async (
  searchParams: SimInventorySearchParamsV2,
  sort: SimInventorySort | null = null,
) => {
  const body = prepareSimInventorySearchBody(searchParams, sort);

  const response = await authFetch(
    `${process.env.REACT_APP_SIM_INVENTORY_REPORTING_API}/sim-product/is-large-report`,
    {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        timeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const { largeReport } = await response.json();

  return largeReport;
};
