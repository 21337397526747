import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { atomWithDefault, atomWithStorage, createJSONStorage, RESET } from 'jotai/utils';
import { isEqual, mapValues } from 'lodash';
import { SIM_INVENTORY_ID_SELECTION } from 'simInventory/Components/Inputs/SimIdSearchInput';
import { SESSION_STATUS, SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { mapURLSearchParamsToSimFilters } from 'simInventory/Filters/utils/filtersToURLSearcParamsMapper';
import { SortingParam } from 'common/hooks/useSorting';
import { createPaginationAtoms } from './utils/filtersFactory';

export const SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS: Omit<
  SimInventoryFiltersV2,
  'customFields'
> = {
  chosenIdType: SIM_INVENTORY_ID_SELECTION.ICCID,
  searchText: '',
  sessionStatus: SESSION_STATUS.UNKNOWN,
  accounts: [],
  mobileNetworkOperator: [],
  connectionId: '',
  orderNumber: '',
  tags: [],
  iccidFrom: '',
  iccidTo: '',
  mnoStatus: [],
};

export const SIM_FILTERS_INITIAL_VALUES: SimInventoryFiltersV2 = {
  ...SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS,
  customFields: {},
};

const filtersAtom = atomWithDefault<SimInventoryFiltersV2>(() => {
  if (window.location.pathname === '/sim-inventory') {
    const params = new URLSearchParams(window.location.search);
    return mapURLSearchParamsToSimFilters(params);
  }

  return SIM_FILTERS_INITIAL_VALUES;
});

export const simFiltersAtom = atom(
  (get) => get(filtersAtom),
  (get, set, filters: SimInventoryFiltersV2 | typeof RESET) => {
    const currentSimFilters = get(filtersAtom);
    if (!isEqual(currentSimFilters, filters)) {
      set(pageFilterAtom, 0);
    }
    if (filters === RESET) {
      const currentFilters = get(filtersAtom);
      const customFields = mapValues(currentFilters.customFields, (customFieldValue) => {
        if (Array.isArray(customFieldValue)) {
          return [];
        }

        return '';
      });

      return set(filtersAtom, { ...SIM_FILTERS_INITIAL_VALUES, customFields });
    }

    return set(filtersAtom, filters);
  },
);

export const sessionStatusFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('sessionStatus'),
);

export const accountsFilterAtom = focusAtom(simFiltersAtom, (optic) => optic.prop('accounts'));

export const mobileNetworkOperatorFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('mobileNetworkOperator'),
);

export const connectionIdFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('connectionId'),
);

export const orderNumberFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('orderNumber'),
);

export const tagsFilterAtom = focusAtom(simFiltersAtom, (optic) => optic.prop('tags'));

export const iccidRangeFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.pick(['iccidFrom', 'iccidTo']),
);

export const chosenIdTypeFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('chosenIdType'),
);

export const customFieldsFilterAtom = focusAtom(simFiltersAtom, (optic) =>
  optic.prop('customFields'),
);

export const mnoStatusFilterAtom = focusAtom(simFiltersAtom, (optic) => optic.prop('mnoStatus'));

export const searchTextFilterAtom = focusAtom(simFiltersAtom, (optic) => optic.prop('searchText'));

export const hasActiveFilterAtom = atom((get) => {
  const filters = get(simFiltersAtom);

  return Object.entries(filters).some(([key, value]) => {
    if (key === 'chosenIdType' || key === 'searchText') {
      return false;
    }

    if (key === 'customFields') {
      const customField = Object.values(value as any);
      return customField.some((value) => value !== '' && !isEqual(value, []));
    }

    if (key === 'tags' && isEqual(value, [])) {
      return false;
    }

    if (key === 'mnoStatus' && isEqual(value, [])) {
      return false;
    }

    if (key === 'accounts' && isEqual(value, [])) {
      return false;
    }

    if (key === 'mobileNetworkOperator' && isEqual(value, [])) {
      return false;
    }

    //@ts-ignore
    return value !== SIM_FILTERS_INITIAL_VALUES[key];
  });
});

export const { rowsPerPageFilterAtom, pageFilterAtom } = createPaginationAtoms();

const STORAGE_VERSION = 'v1';
const STORAGE_NAME = `sim-order`;
const STORAGE_KEY = `${STORAGE_VERSION}-${STORAGE_NAME}`;

const atomLocalStorage = createJSONStorage<SortingParam>(() => localStorage);
export const simSortingAtomWithStorage = atomWithStorage<SortingParam>(
  STORAGE_KEY,
  {
    columnName: 'iccid',
    sort: 'asc',
  } as SortingParam,
  atomLocalStorage,
  { getOnInit: true },
);
