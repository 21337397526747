import { RSimTable } from './Inventory/RSimTable';
import { useSearchParams } from 'react-router-dom';

import { RSimFilters } from './Filters/RSimFilters';
import { TagProvider } from 'tags/TagProvider';
import { TableLayout } from 'common/components/Layouts/TableLayout/TableLayout';
import { rsimFiltersAtom } from 'atoms/filters/rsim-products';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { mapRSimFiltersToURLSearchParams } from './Filters/utils/filtersToURLSearcParamsMapper';
import { RSimFiltersToURLParser } from './Filters/Components/SearchParams/RSimFiltersToURLParser';

export const RSimInventory = () => {
  return (
    <TagProvider type="rsim">
      <TableLayout>
        <RSimFilters />
        <RSimTable />
      </TableLayout>
    </TagProvider>
  );
};

export const RSimInventoryTab = () => {
  const [, setSearhParams] = useSearchParams();

  const filters = useAtomValue(rsimFiltersAtom);

  useEffect(() => {
    setSearhParams(mapRSimFiltersToURLSearchParams(filters));
  }, [filters]);
  return (
    <RSimFiltersToURLParser>
      <RSimInventory />
    </RSimFiltersToURLParser>
  );
};
