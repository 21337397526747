import React from 'react';
import { AutoHideSnackbar } from '../../Snackbar/AutoHideSnackbar';
import { AlertTitle } from '../../Snackbar/AlertTitle';
import Typography from '@mui/material/Typography';

export type ActivationError = {
  title: string;
  description: string[];
};

export type ExportCardsSnackbarsProps = {
  setError: (error: ActivationError | null) => void;
  error: ActivationError | null;
};

export const ActivationSnackbar: React.FC<ExportCardsSnackbarsProps> = ({ setError, error }) => {
  return (
    <>
      <AutoHideSnackbar
        severity="error"
        open={!!error}
        onClose={() => {
          setError(null);
        }}
      >
        {''} {/* Without this component throw a error and crash app */}
        <AlertTitle>{error?.title || ''}</AlertTitle>
        {error?.description.map((d, index) => (
          <div key={index}>
            <Typography variant="text1" color="text.white">
              {d}
            </Typography>
          </div>
        ))}
      </AutoHideSnackbar>
    </>
  );
};
