import React, { ReactElement, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import {
  simInventoryDetails,
  simInventoryDetailsRSimView,
  statusLabelIcon,
} from './SimInventory.style';
import Grid from '@mui/material/Grid2';
import { SectionGridRow } from '../../common/components/SectionGrid/SectionGridRow';
import { SectionGridItem } from '../../common/components/SectionGrid/SectionGridItem';
import { getSessionStatusTranslatedLabel, SESSION_STATUS_UI } from '../utils/sessionStatus';
import { SessionStatusIndicator } from '../Components/SessionStatusIndicator';
import Typography from '@mui/material/Typography';
import { ContentCopyExtension } from '../../common/ContentCopyExtension/ContentCopyExtension';
import { TagDisplay } from '../../tags/TagsDisplay';
import { Tag } from '../../tags/services/tagsApi.types';
import { CopyableLink } from '../../common/components/Table/Link';
import { Routes } from '../../Routes.constants';
import { useSimDetails } from '../hooks/useSimDetails';
import mapValues from 'lodash/mapValues';
import { useTranslation } from 'react-i18next';
import { simGridSizes } from '../../rSimInventory/Components/RSimInventoryDetails/RSimInventoryDetailsPage.style';
import { useMediaQuery } from '@mui/material';
import { CustomFieldValue } from '../SimInventoryApi/simInventoryApi.interface';
import { FIELD_TYPE } from '../../admin/customFields/entities/customFields.entity';
import { getHuminizeDurationInSecFunction } from 'i18n/dateLocale';
import { areLabelsExist } from '../../common/utils/areLabelsExist';
import { BillingBadge } from 'common/components/ConfigurableTable/Components/Badges/BillingBadge';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { SimDetailsContext } from './SimDetailsProvider';
import { OrderSection } from '../../common/components/Order/Details/OrderSection';
import { DeviceSection } from '../../common/components/Device/Details/DeviceSection';

const humanize = getHuminizeDurationInSecFunction();

export const SimInventoryDetails = ({
  iccid,
  rSimView = false,
  rSimViewSimProfileRow,
  testId,
}: {
  iccid: string | undefined;
  rSimView?: boolean;
  rSimViewSimProfileRow?: ReactElement | null;
  testId?: string | null;
}) => {
  const { t } = useTranslation();

  const matchesMin = useMediaQuery('(min-width:1536px)');
  const matchesMax = useMediaQuery('(max-width:1730px)');

  const { data } = useSimDetails(iccid!);
  const simInventoryEntry = useMemo(
    () =>
      mapValues(data, (value) => {
        if (value === '') {
          return '-';
        }

        return value;
      }),
    [data],
  );

  const simProfilesVisibleKeys = ['sessionStatus', 'simType'];
  const simProfilesVisible = areLabelsExist(simInventoryEntry, simProfilesVisibleKeys);

  const mobileNetworkOperatorVisibleKeys = [
    'mobileNetworkOperator',
    'organisation',
    'commsPlan',
    'mnoStatus',
    'mnoStatusEntryTime',
    'mnoRatePlan',
  ];
  const mobileNetworkOperatorVisible = areLabelsExist(
    simInventoryEntry,
    mobileNetworkOperatorVisibleKeys,
  );

  const clientVisibleKeys = ['accountName', 'accountRef', 'customerCommsPlan'];
  const clientVisible = !rSimView && areLabelsExist(simInventoryEntry, clientVisibleKeys);

  const identificationNumbersVisibleKeys = ['iccid', 'imsi', 'msisdn', 'euicc'];
  const identificationNumbersVisible = areLabelsExist(
    simInventoryEntry,
    identificationNumbersVisibleKeys,
  );

  const sessionDetailsVisibleKeys = [
    'sessionStatus',
    'sessionStatusLastUpdated',
    'sessionStartTime',
    'sessionEndTime',
    'apn',
    'localization',
    'rasClient',
    'framedProtocol',
    'nasIpAddress',
    'serviceType',
    'userName',
  ];
  const sessionDetailsVisible =
    !rSimView && areLabelsExist(simInventoryEntry, sessionDetailsVisibleKeys);

  const usageVisibleKeys = [
    'liveDataUsage',
    'inMinuteUsage',
    'outMinuteUsage',
    'inTextUsage',
    'outTextUsage',
  ];
  const usageVisible = areLabelsExist(simInventoryEntry, usageVisibleKeys);

  const mappedCustomFields: CustomFieldValue[] = [];
  simInventoryEntry?.customFields?.forEach((field: CustomFieldValue) => {
    const index = mappedCustomFields.findIndex((f) => f.label === field.label);
    if (index > -1) {
      const values = mappedCustomFields[index].selectionOption?.split(', ') || [];
      if (field.selectionOption && !values.includes(field.selectionOption)) {
        values.push(field.selectionOption);
      }
      mappedCustomFields[index].selectionOption = values.join(', ');
    } else {
      mappedCustomFields.push(field);
    }
  });

  const productsViewEnabled = useHasFeatureFlag('ProductsView');

  const { baseRoute } = useContext(SimDetailsContext);

  return (
    <Box sx={!rSimView ? simInventoryDetails : simInventoryDetailsRSimView} data-testid={testId}>
      <Grid container rowSpacing={12} direction="column">
        {simProfilesVisible && !rSimView ? (
          <>
            <SectionGridRow
              hideTopBorder={true}
              sx={{ pl: rSimView ? 0 : 3 }}
              title={t('simInventory.simProfile')}
            >
              <>
                <SectionGridItem
                  label={
                    !rSimView ? t('simInventory.sessionStatus') : t('simInventory.profileStatus')
                  }
                  customGridSizes={rSimView ? simGridSizes : null}
                  testId="sessionProfileStatus"
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {simInventoryEntry.sessionStatus !== SESSION_STATUS_UI.NONE ? (
                      <Box sx={statusLabelIcon}>
                        <SessionStatusIndicator status={simInventoryEntry.sessionStatus} />
                      </Box>
                    ) : null}
                    <Typography variant="text1" color="text.primary">
                      <ContentCopyExtension
                        value={getSessionStatusTranslatedLabel(simInventoryEntry.sessionStatus)}
                      >
                        {getSessionStatusTranslatedLabel(simInventoryEntry.sessionStatus)}
                      </ContentCopyExtension>
                    </Typography>
                  </Box>
                </SectionGridItem>

                <SectionGridItem
                  value={simInventoryEntry?.sessionStatusLastUpdated}
                  label={t('simInventory.sessionStatusLastUpdated_flat')}
                  testId="sessionStatusLastUpdatedSimProfile"
                  customGridSizes={rSimView ? simGridSizes : null}
                />
                <SectionGridItem
                  label={t('simInventory.billingStatus')}
                  testId="billingStatus"
                  customGridSizes={rSimView ? simGridSizes : null}
                >
                  {simInventoryEntry?.billingStatus !== undefined ? (
                    <BillingBadge billingStatus={simInventoryEntry?.billingStatus} />
                  ) : undefined}
                </SectionGridItem>
                <SectionGridItem
                  value={simInventoryEntry?.simType}
                  label={t('simInventory.simType')}
                  testId="simType"
                  customGridSizes={rSimView ? simGridSizes : null}
                />
                <SectionGridItem
                  value={simInventoryEntry?.productCode}
                  label={t('simInventory.productCode')}
                  testId="productCode"
                  customGridSizes={rSimView ? simGridSizes : null}
                />
              </>
            </SectionGridRow>
            <SectionGridRow hideTopBorder={true} sx={{ pb: 3, pl: rSimView ? 0 : 3 }}>
              <SectionGridItem
                testId="tags"
                label={t('common.tags')}
                customGridSizes={
                  rSimView
                    ? simGridSizes
                    : {
                        xsSize: 12,
                        smSize: 12,
                        mdSize: 12,
                        lgSize: 12,
                        xlSize: 12,
                      }
                }
              >
                {!!simInventoryEntry?.tags?.items && simInventoryEntry?.tags?.items.length > 0 ? (
                  <TagDisplay
                    id={iccid!}
                    initialTags={simInventoryEntry.tags?.items.map((item: Tag) => item.name) || []}
                    hasMore={simInventoryEntry.tags?.hasMore || false}
                    shouldWrap={true}
                    preventRefetch
                  />
                ) : (
                  <span>-</span>
                )}
              </SectionGridItem>
            </SectionGridRow>
          </>
        ) : null}
        {rSimView ? rSimViewSimProfileRow : null}
        {mobileNetworkOperatorVisible ? (
          <SectionGridRow
            title={t('simInventory.mobileNetworkOperatorSectionLabel')}
            sx={{ pl: rSimView ? 0 : 3 }}
          >
            <>
              <SectionGridItem
                testId={'mobileNetworkOperator'}
                value={simInventoryEntry?.mobileNetworkOperator}
                label={t('simInventory.mobileNetworkOperator')}
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                testId={'commsPlan'}
                value={simInventoryEntry?.commsPlan}
                label={t('simInventory.commsPlan')}
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                testId={'organisation'}
                value={simInventoryEntry?.organisation}
                label={t('simInventory.organisation')}
                customGridSizes={rSimView ? simGridSizes : null}
              />

              <SectionGridItem
                testId={'mnoStatus'}
                value={simInventoryEntry?.mnoStatus}
                label={t('simInventory.mnoStatus')}
                customGridSizes={rSimView ? simGridSizes : null}
              />

              <SectionGridItem
                testId={'mnoStatusEntryTime'}
                value={simInventoryEntry?.mnoStatusEntryTime}
                label={
                  !rSimView
                    ? t('simInventory.mnoStatusEntryTime')
                    : t('simInventory.changeStatusTime')
                }
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                testId={'mnoRatePlan'}
                value={simInventoryEntry?.mnoRatePlan}
                label="MNO Rate Plan"
                customGridSizes={rSimView ? simGridSizes : null}
              />
            </>
          </SectionGridRow>
        ) : null}
        {clientVisible ? (
          <SectionGridRow title={t('simInventory.client')}>
            <>
              <SectionGridItem
                testId="accountName"
                value={simInventoryEntry?.accountName}
                label={t('common.accountName')}
              />
              <SectionGridItem
                testId="accountRef"
                value={simInventoryEntry?.accountRef}
                label={t('common.accountNumber')}
              />
              <SectionGridItem
                testId="customerCommsPlan"
                value={simInventoryEntry?.customerCommsPlan}
                label={t('simInventory.customerCommsPlan')}
              />
            </>
          </SectionGridRow>
        ) : null}
        {identificationNumbersVisible ? (
          <SectionGridRow
            title={t('simInventory.identificationNumbers')}
            sx={{ pl: rSimView ? 0 : 3 }}
          >
            <>
              {!rSimView ? (
                <SectionGridItem
                  testId={'iccid'}
                  value={simInventoryEntry?.iccid}
                  label={t('common.iccid')}
                  customGridSizes={rSimView ? simGridSizes : null}
                />
              ) : (
                <SectionGridItem {...simGridSizes} label={t('common.iccid')} testId={`iccid`}>
                  <CopyableLink
                    value={simInventoryEntry?.iccid!}
                    to={`${baseRoute}/${simInventoryEntry?.iccid!}`}
                    sx={{
                      maxWidth: matchesMin && matchesMax ? '135px' : '100%',
                      overflowWrap: 'break-word',
                    }}
                  />
                </SectionGridItem>
              )}
              <SectionGridItem
                testId={'imsi'}
                value={simInventoryEntry?.imsi}
                label={t('common.imsi')}
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                testId={'msisdn'}
                value={simInventoryEntry?.msisdn}
                label={t('common.msisdn')}
                customGridSizes={rSimView ? simGridSizes : null}
              />
              {!rSimView ? (
                <SectionGridItem
                  testId={'eid'}
                  label={t('common.eid')}
                  customGridSizes={rSimView ? simGridSizes : null}
                >
                  {simInventoryEntry?.euicc ? (
                    <CopyableLink
                      value={simInventoryEntry?.euicc!}
                      to={`${
                        productsViewEnabled ? Routes.productsRsim : Routes.rSimInventory
                      }/${simInventoryEntry?.euicc!}`}
                    />
                  ) : (
                    <>-</>
                  )}
                </SectionGridItem>
              ) : null}
            </>
          </SectionGridRow>
        ) : null}
        {!rSimView && <DeviceSection deviceData={simInventoryEntry} />}
        {!rSimView && <OrderSection orderData={simInventoryEntry} />}
        {usageVisible ? (
          <SectionGridRow
            title={
              !rSimView ? t('simInventory.usageInCycle') : t('simInventory.profileUsageInCycle')
            }
            sx={{ pl: rSimView ? 0 : 3 }}
          >
            <>
              <SectionGridItem
                value={simInventoryEntry?.liveDataUsage}
                label={t('common.data')}
                testId={'dataUsage'}
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                value={
                  simInventoryEntry?.inMinuteUsage && humanize(simInventoryEntry?.inMinuteUsage)
                }
                label={t('common.inMinuteUsage')}
                testId="inVoiceUsage"
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                value={
                  simInventoryEntry?.outMinuteUsage && humanize(simInventoryEntry?.outMinuteUsage)
                }
                label={t('common.outMinuteUsage')}
                testId="outVoiceUsage"
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                value={simInventoryEntry?.inTextUsage}
                label={t('common.inTextUsage')}
                testId="inSmsUsage"
                customGridSizes={rSimView ? simGridSizes : null}
              />
              <SectionGridItem
                value={simInventoryEntry?.outTextUsage}
                label={t('common.outTextUsage')}
                testId="outSmsUsage"
                customGridSizes={rSimView ? simGridSizes : null}
              />
            </>
          </SectionGridRow>
        ) : null}
        {sessionDetailsVisible ? (
          <SectionGridRow title={t('simInventory.lastSessionDetails')}>
            <>
              <SectionGridItem
                value={getSessionStatusTranslatedLabel(simInventoryEntry?.sessionStatus)}
                label={t('simInventory.sessionStatus')}
                testId="sessionStatus"
              />
              <SectionGridItem
                value={simInventoryEntry?.sessionStatusLastUpdated}
                label={t('simInventory.sessionStatusLastUpdated_flat')}
                testId="sessionStatusLastUpdatedSessionDetails"
              />
              <SectionGridItem
                value={simInventoryEntry?.sessionStartTime}
                label={t('simInventory.sessionStartTime')}
                testId="sessionStartTime"
              />
              <SectionGridItem
                value={simInventoryEntry?.sessionEndTime}
                label={t('simInventory.sessionEndTime')}
                testId="sessionEndTime"
              />
              <SectionGridItem
                value={simInventoryEntry?.cumulativeTotalUsage}
                label={t('simInventory.cumulativeUsage')}
                testId="cumulativeUsage"
              />
              <SectionGridItem
                value={simInventoryEntry?.apn}
                label={t('simInventory.apn')}
                testId="apnLastSessionDetails"
              />
              <SectionGridItem
                value={simInventoryEntry?.localization}
                label={t('simInventory.location')}
                testId="location"
              />
              <SectionGridItem
                value={simInventoryEntry?.ratType}
                label={t('simInventory.lastRat')}
                testId="ratType"
              />
              <SectionGridItem
                value={simInventoryEntry?.rasClient}
                label={t('simInventory.rasClient')}
                testId="rasClient"
              />
              <SectionGridItem
                value={simInventoryEntry?.framedProtocol}
                label={t('simInventory.framedProtocol')}
                testId="framedProtocol"
              />
              <SectionGridItem
                value={simInventoryEntry?.nasIpAddress}
                label={t('simInventory.nasIpAddress')}
                testId="nasIpAddress"
              />
              <SectionGridItem
                value={simInventoryEntry?.serviceType}
                label={t('simInventory.serviceType')}
                testId="serviceType"
              />
              <SectionGridItem
                value={simInventoryEntry?.userName}
                label={t('simInventory.userName')}
                testId="userName"
              />
            </>
          </SectionGridRow>
        ) : null}
        {mappedCustomFields.length > 0 ? (
          <SectionGridRow title={t('simInventory.customFields')} sx={{ pl: rSimView ? 0 : 3 }}>
            <>
              {mappedCustomFields.map((field) => (
                <SectionGridItem
                  key={field.label}
                  value={
                    field.dataType === FIELD_TYPE.Dropdown ? field.selectionOption : field.textValue
                  }
                  label={field.label}
                  testId={`customField-${field.label}`}
                  customGridSizes={rSimView ? simGridSizes : null}
                />
              ))}
            </>
          </SectionGridRow>
        ) : null}
      </Grid>
    </Box>
  );
};
