import { CustomField } from 'admin/customFields/entities/customFields.entity';
import { customFieldsFilterAtom } from 'atoms/filters/sim';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useEnabledCustomFieldQuery } from 'admin/customFields/hooks/useEnabledCustomFieldQuery';

export const useCustomFieldsFiltersInitialization = () => {
  const { isFetched, data } = useEnabledCustomFieldQuery();

  const enabledCustomFields = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((f: CustomField) => f.enabled);
  }, [data]);

  const setCustomFieldsFilters = useSetAtom(customFieldsFilterAtom);

  useEffect(() => {
    if (isFetched && enabledCustomFields?.length && enabledCustomFields?.length > 0) {
      setCustomFieldsFilters((currentFilters: any) => {
        const newCustomFilters = enabledCustomFields.reduce((filters, customFields) => {
          let currentValue = currentFilters[customFields.id.toString()];
          if (!currentValue) {
            currentValue = customFields.dataType === 'Dropdown' ? [] : '';
          }

          return {
            ...filters,
            [customFields.id.toString()]: currentValue,
          };
        }, {} as Record<string, string | string[]>);

        return newCustomFilters;
      });
    }
  }, [isFetched, data]);
};
