import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import {
  createCustomField,
  deleteCustomField,
  editCustomField,
  editSimCustomFieldsData,
  fetchCustomFields,
  saveSelectionOptions,
} from '../api/customFieldsApi';
import {
  CreateCustomField,
  EditCustomField,
  EditCustomFieldSelectionOptions,
} from '../entities/customFields.entity';
import { useAbility } from 'permissions/hooks/useAbility';
import { Actions, Subjects } from 'permissions/ability';
import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { customFieldsQuery } from '../utils/queries';

export const useCustomFields = () => {
  const ability = useAbility();
  const customFieldsEnabled = ability.can(Actions.read, Subjects.customFields);
  const customFieldsMigrated = useHasFeatureFlag('CustomFieldsMigratedToProductsView');

  const {
    data: customFields,
    refetch,
    status,
    isRefetching,
  } = useSuspenseQuery({
    queryKey: customFieldsQuery.all,
    queryFn: async () =>
      customFieldsEnabled ? setMinimalPromiseTime(fetchCustomFields(customFieldsMigrated)) : null,
  });

  const queryClient = useQueryClient();

  const { mutate: createField } = useMutation({
    mutationFn: async (data: CreateCustomField) => {
      return createCustomField(data, customFieldsMigrated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customFieldsQuery.all });
    },
  });

  const { mutate: editField } = useMutation({
    mutationFn: async (data: EditCustomField) => {
      return editCustomField(data, customFieldsMigrated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customFieldsQuery.all });
    },
  });

  const { mutate: deleteField } = useMutation({
    mutationFn: async (id: number) => {
      return deleteCustomField(id, customFieldsMigrated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customFieldsQuery.all });
    },
  });

  const { mutate: editSelectionOptions } = useMutation({
    mutationFn: async (data: EditCustomFieldSelectionOptions) => {
      return saveSelectionOptions(data.id, data.selectionOptions, customFieldsMigrated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customFieldsQuery.all });
    },
  });

  const { mutateAsync: editSimCustomFields } = useMutation({
    mutationFn: async (data: { iccid: string; customFields: { id: number; value: string }[] }) => {
      return editSimCustomFieldsData(data.iccid, data.customFields, customFieldsMigrated);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: customFieldsQuery.all });
    },
  });

  return {
    customFields,
    createField,
    editField,
    deleteField,
    editSimCustomFields,
    refetch,
    status,
    isRefetching,
    editSelectionOptions,
  };
};
