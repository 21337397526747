import { RSimIdSearchInput } from 'rSimInventory/Components/Inputs/RSimIdSearchInput';
import { useAtom, useSetAtom } from 'jotai';
import {
  chosenIdTypeFilterAtom,
  eidRangeFilterAtom,
  searchTextFilterAtom,
} from 'atoms/filters/rsim-products';
import { useCallback } from 'react';

export const IdSearchInput = () => {
  const [chosenIdType, setChosenIdType] = useAtom(chosenIdTypeFilterAtom);

  const [searchText, setSearchText] = useAtom(searchTextFilterAtom);

  const setEidRange = useSetAtom(eidRangeFilterAtom);

  const onChange = useCallback((value: string) => {
    setSearchText(value);
    if (value !== '') {
      setEidRange({ eidFrom: '', eidTo: '' });
    }
  }, []);

  return (
    <RSimIdSearchInput
      chosenId={chosenIdType}
      onChosenIdChange={setChosenIdType}
      search={searchText}
      onSearchChange={onChange}
    />
  );
};
