import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { DefaultThemeProvider } from 'theme/DefaultThemeProvider';
import { AuthProvider } from 'auth/AuthProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallbackWithAuthorizationRedirect } from 'auth/ErrorFallbackWithAuthorizationRedirect';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AppRoutes } from './AppRoutes';
import { ErrorCodes } from 'common/errorCodes';

import { SignalRProvider } from 'signalr/SignalRProvider';
import { getDateLocal } from 'i18n/dateLocale';
import { AbilityContextProvider } from 'permissions/PermissionProvider';
import { FeatureFlagProvider } from 'featureFlags/FeatureFlagProvider';
import { NavigationAtomsInitiator } from 'common/components/AppLayout/AppNavigation/Components/NavigationAtomsInitiator';
import { RSimFiltersURLParser } from 'rSimInventory/Filters/Components/SearchParams/RSimFiltersURLParser';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof Error && error.message === ErrorCodes.AccessDenied) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

export const App = () => {
  return (
    <DefaultThemeProvider>
      <Box
        sx={{
          fontFamily: 'Hind',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallbackWithAuthorizationRedirect}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocal()}>
              <AuthProvider>
                <FeatureFlagProvider>
                  <SignalRProvider>
                    <AbilityContextProvider>
                      <CssBaseline />

                      <BrowserRouter>
                        <NavigationAtomsInitiator>
                          <RSimFiltersURLParser>
                            <AppRoutes />
                          </RSimFiltersURLParser>
                        </NavigationAtomsInitiator>
                      </BrowserRouter>
                    </AbilityContextProvider>
                  </SignalRProvider>
                </FeatureFlagProvider>
              </AuthProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </Box>
    </DefaultThemeProvider>
  );
};
