import { useMemo } from 'react';
import { useCustomFieldFromQueryCache } from './useCustomFieldFromQueryCache';

export const useEnabledCustomFieldFromQueryCache = () => {
  const { data, ...props } = useCustomFieldFromQueryCache();

  const enabledCustomFields = useMemo(() => data.filter((f) => f.enabled), [data]);

  return {
    ...props,
    data: enabledCustomFields,
  };
};
