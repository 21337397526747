import { accountsFilterAtom } from 'atoms/filters/rsim-products';
import { FilterAsyncChip } from 'common/components/FilterChip/FilterAsyncChip';
import { useAtom } from 'jotai';
import React from 'react';
import { fetchAccount } from 'rSimInventory/Filters/services/accountsApi';

export const RSimAccountChips = () => {
  const [accounts, setAccounts] = useAtom(accountsFilterAtom);

  const onClear = (id: string) => {
    setAccounts((currentAccounts) => currentAccounts.filter((account) => account !== id));
  };

  if (accounts.length === 0) {
    return null;
  }

  return (
    <>
      {accounts.map((account) => {
        return (
          <React.Fragment key={account}>
            <FilterAsyncChip
              labelPrefix="Account"
              onCancel={() => {
                console.log('asdas');
                onClear(account);
              }}
              fieldName="accountName"
              queryFn={() => {
                return fetchAccount(account);
              }}
              queryKey={['account', account]}
              queryEnabled={!!account}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
