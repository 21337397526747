import { SectionGridItem } from 'common/components/SectionGrid/SectionGridItem';
import { SectionGridRow } from 'common/components/SectionGrid/SectionGridRow';
import { useTranslation } from 'react-i18next';
import { useSectionAvailability } from '../../SectionGrid/hooks/useSectionAvailability';

export type DeviceData = {
  imei?: string | null;
  managementIpAddress?: string | null;
  currentIpAddress?: string | null;
  apn?: string | null;
  activeConfiguration?: string | null;
};

export type OrderSectionProps = {
  deviceData: DeviceData;
};

export const DeviceSection = ({ deviceData }: OrderSectionProps) => {
  const { t } = useTranslation();

  const available = useSectionAvailability({
    data: deviceData,
    props: ['imei', 'managementIpAddress', 'currentIpAddress', 'apn', 'activeConfiguration'],
  });

  return available ? (
    <SectionGridRow title={t('simInventory.device')} data-testid="deviceSection">
      <>
        <SectionGridItem
          value={deviceData?.imei}
          label={t('simInventory.detectedImei')}
          testId="imei"
        />
        <SectionGridItem
          value={deviceData?.managementIpAddress}
          label={t('simInventory.managementIp')}
          testId="managementIpAddress"
        />
        <SectionGridItem
          value={deviceData?.currentIpAddress}
          label={t('products.sim.lastKnownIP')}
          testId="currentIpAddress"
        />
        <SectionGridItem value={deviceData?.apn} label={t('simInventory.apn')} testId="apn" />
        <SectionGridItem
          value={deviceData?.activeConfiguration}
          label={t('simInventory.activeConfiguration')}
          testId="activeConfiguration"
        />
      </>
    </SectionGridRow>
  ) : null;
};
