import { useTranslation } from 'react-i18next';
import { ActivationRequestFailedError, provisionSim } from './api/simActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AuthError } from 'auth/utils';
import {
  ActivationError,
  ActivationSnackbar,
} from 'common/components/Activation/ActivationSnackbar';

type DeactivationDialogProps = {
  data: any;
  open: boolean;
  onClose: () => void;
};

export const DeactivationDialog = ({ data, open, onClose }: DeactivationDialogProps) => {
  const { t } = useTranslation();

  const [deactivationError, setDeactivationError] = useState<ActivationError | null>(null);

  const { mutate } = useMutation({
    mutationKey: ['sim-provisisioning'],
    mutationFn: async () => {
      await provisionSim([data.connectionId], false);
    },
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      } else if (error instanceof ActivationRequestFailedError) {
        setDeactivationError({
          title: t('common.activationRequestFailed'),
          description: [
            t('common.anotherRequestStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setDeactivationError({ title: error.message, description: [] });
      }
    },
  });

  return (
    <>
      <ConfirmActionDialog
        open={open}
        data={data}
        onClose={onClose}
        submit={async () => mutate()}
        message={`${t('simInventory.deactivationIccid')} ${data?.iccid}`}
        title={t('simInventory.deactivation')}
      />
      <ActivationSnackbar error={deactivationError} setError={setDeactivationError} />
    </>
  );
};
