import {
  SIM_FILTERS_INITIAL_VALUES,
  SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS,
} from 'atoms/filters/sim';
import { cleanDefaultValuesURLSearchParams } from 'common/utils/urlSearchParamsUtils';

import { pick } from 'lodash';
import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { parseSessionStatus } from './filterParsers.utils';
import {
  addDropdownValueToCustomFieldFilter,
  addTextValueToCustomFieldFilter,
  parseCustomFieldStringToSearchBody,
} from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { MNOStatus } from 'simInventory/SimInventoryApi/simInventoryApi.interface';

export const mapCustomFieldsToURL = (customFields: Record<string, string | string[]>) => {
  const parsedCF = Object.entries(customFields).reduce(
    (customFieldsString: string, [id, value]) => {
      if (Array.isArray(value)) {
        return addDropdownValueToCustomFieldFilter(customFieldsString, id, value);
      } else {
        return addTextValueToCustomFieldFilter(customFieldsString, id, value);
      }
    },
    '',
  );

  return parsedCF;
};

export const mapSimFiltersToURLSearchParams = (simFilters: SimInventoryFiltersV2) => {
  const { customFields, sessionStatus, ...othersFilters } = simFilters;

  const cleanedFiltersValues = cleanDefaultValuesURLSearchParams(
    { ...othersFilters, sessionStatus: sessionStatus.toString() },
    {
      ...SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS,
      sessionStatus: SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS.sessionStatus.toString(),
    },
  );

  const searcParams = new URLSearchParams({
    ...cleanedFiltersValues,
  });

  const stringifiedCustomFields = mapCustomFieldsToURL(customFields);
  if (stringifiedCustomFields !== '') {
    searcParams.append('customFields', stringifiedCustomFields);
  }

  return searcParams;
};

export const mapURLSearchParamsToSimFilters = (urlSearchParams: URLSearchParams) => {
  const filters = Object.fromEntries(urlSearchParams);

  const {
    tags,
    accounts,
    mobileNetworkOperator,
    mnoStatus,
    sessionStatus,
    customFields,
    ...cleanedFiltersValues
  } = pick(filters, Object.keys(SIM_FILTERS_INITIAL_VALUES));

  let parsedCustomFields = {};
  if (customFields) {
    const parsedCustomFieldsArray = parseCustomFieldStringToSearchBody(customFields);
    parsedCustomFields = parsedCustomFieldsArray.reduce((customFields, customField) => {
      if (customField.textValue) {
        return { ...customFields, [customField.id]: customField.textValue };
      } else if (customField.selectionOptionIds) {
        return { ...customFields, [customField.id]: customField.selectionOptionIds };
      }

      return customFields;
    }, {} as Record<string, string | string[]>);
  }

  return {
    ...SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS,
    ...cleanedFiltersValues,
    sessionStatus: parseSessionStatus(sessionStatus),
    tags: tags ? tags.split(',') : SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS.tags,
    accounts: accounts
      ? accounts.split(',')
      : SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS.accounts,
    mobileNetworkOperator: mobileNetworkOperator
      ? mobileNetworkOperator.split(',')
      : SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS.mobileNetworkOperator,
    mnoStatus: mnoStatus
      ? (mnoStatus.split(',').map((value) => parseInt(value)) as MNOStatus[])
      : SIM_FILTERS_INITIAL_VALUES_WITHOUT_CUSTOM_FIELDS.mnoStatus,
    customFields: parsedCustomFields,
  };
};
