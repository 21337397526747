import React from 'react';
import { createSignalRContext } from 'react-signalr';
import { useHasFeatureFlag } from '../featureFlags/useHasFeatureFlag';

export const SignalRContext = createSignalRContext();

export const SignalRProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const signalrToIngestionServiceEnabled =
    useHasFeatureFlag('SignalrToIngestionServiceEnabled') || false;
  return (
    <SignalRContext.Provider
      url={
        !signalrToIngestionServiceEnabled
          ? process.env.REACT_APP_SIM_INVENTORY_SIGNALR_OLD!
          : process.env.REACT_APP_SIM_INVENTORY_SIGNALR!
      }
      dependencies={[]}
    >
      {children}
    </SignalRContext.Provider>
  );
};
