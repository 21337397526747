import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  AutocompleteSelect,
  Option,
} from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import { useDebounce } from 'common/useDebounce';
import { useTranslation } from 'react-i18next';
import { SetStateAction, useAtom, WritableAtom } from 'jotai';
import { fetchAccounts } from 'rSimInventory/Filters/services/accountsApi';
import { uniqBy } from 'lodash';

const MAX_ACCOUNTS_TO_FILTER = 10;

type AccountSearchFilterProps = {
  accountsFilterAtom: WritableAtom<string[], [SetStateAction<string[]>], void>;
  accountsHintsAtom: WritableAtom<any, [any], void>;
};

export const AccountSearchFilter = ({
  accountsFilterAtom,
  accountsHintsAtom,
}: AccountSearchFilterProps) => {
  const { t } = useTranslation();

  const [hints, removeHints] = useAtom(accountsHintsAtom);

  const [accountSearch, setAccountSearch] = useState('');
  const debounceAccount = useDebounce(accountSearch, 500);

  const [accounts, setAccounts] = useAtom(accountsFilterAtom);

  const [savedAccounts, setSavedAccounts] = useState<Option[]>([]);

  const { data: accountsData, isFetched } = useQuery({
    queryKey: ['accounts', debounceAccount],
    queryFn: async () => {
      const accounts = await fetchAccounts(debounceAccount);
      const accountOptions = accounts.map((account) => ({
        id: account.accountRef.toString(),
        name: account.accountName,
        toAdd: false,
        showBothIdAndName: true,
      }));

      return accountOptions;
    },
    initialData: [],
  });

  useEffect(() => {
    setSavedAccounts((currentData) =>
      uniqBy([...currentData, ...accountsData], (option) => option.id),
    );
  }, [accountsData]);

  const { errors, warnings } = useMemo(() => {
    let errors: string[] = [];
    let warnings: string[] = [];
    if (accounts && accounts.length >= MAX_ACCOUNTS_TO_FILTER + 1) {
      errors = [t('common.limitReached')];
      warnings = [];
    } else if (accounts && accounts.length >= MAX_ACCOUNTS_TO_FILTER) {
      errors = [];
      warnings = [t('common.limitReached')];
    }

    return { errors, warnings };
  }, [accounts]);

  const accountsHint = hints
    ?.map((accountId: string) => {
      const account = accountsData?.find((validAccount) => validAccount.id === accountId);
      return account;
    })
    .filter((account: any) => !!account);

  return (
    <AutocompleteSelect
      testId="accounts-multiselect"
      //@ts-ignore
      hints={accountsHint || []}
      errors={errors}
      warnings={warnings}
      values={accounts}
      options={savedAccounts!}
      onChange={(values) => {
        setAccounts(values.map((value) => value.id));
      }}
      onRemoveHint={removeHints}
      allowAdding={false}
      inputValue={accountSearch}
      onTextInputChange={(val) => {
        setAccountSearch(val);
      }}
      allowFilteringById={true}
      placeholder={t('rSimInventory.accountFilterPlaceholder')}
      isLoading={!isFetched}
    />
  );
};
