import { isUndefined } from 'lodash';
import { useMemo } from 'react';
import { DeviceData } from 'common/components/Device/Details/DeviceSection';

type RSimDeviceData = Pick<DeviceData, 'apn' | 'currentIpAddress' | 'imei'>;

type useRSimDeviceDataProps = {
  simWithLastSession: RSimDeviceData | null;
  primarySim: RSimDeviceData;
  backupSim: RSimDeviceData;
};

const hasAnyCardHaveDefinedValue = (
  primarySim: RSimDeviceData,
  backupSim: RSimDeviceData,
  key: keyof RSimDeviceData,
) => {
  return !isUndefined(primarySim[key]) || !isUndefined(backupSim[key]);
};

export const useRSimDeviceData = ({
  simWithLastSession,
  primarySim,
  backupSim,
}: useRSimDeviceDataProps) => {
  return useMemo(() => {
    let imei = simWithLastSession?.imei;

    if (isUndefined(imei) && hasAnyCardHaveDefinedValue(primarySim, backupSim, 'imei')) {
      imei = null;
    }

    let apn = simWithLastSession?.apn;
    if (isUndefined(apn) && hasAnyCardHaveDefinedValue(primarySim, backupSim, 'imei')) {
      apn = null;
    }

    let currentIpAddress = simWithLastSession?.currentIpAddress;
    if (isUndefined(apn) && hasAnyCardHaveDefinedValue(primarySim, backupSim, 'currentIpAddress')) {
      currentIpAddress = null;
    }

    return {
      imei,
      apn,
      currentIpAddress,
    };
  }, [simWithLastSession, primarySim, backupSim]);
};
