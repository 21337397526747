import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { mnoStatusFilterAtom } from 'atoms/filters/sim';
import { Chip } from 'common/components/Chips/Chip';
import { MultiSelect } from 'common/components/Inputs/MultiSelect/MultiSelect';

import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { MnoStatusFilter } from 'simInventory/models/SimProfile.model';
import {
  MNO_STATUS,
  MNO_STATUS_VALUE_LABEL_MAP,
} from 'simInventory/SimInventoryApi/simInventoryApi.constants';

export const MNOStatusInput = () => {
  const { t } = useTranslation();
  const [mnoStatus, setMNOStatus] = useAtom(mnoStatusFilterAtom);

  const MNOStatusOptions = [
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.ACTIVE],
      value: MNO_STATUS.ACTIVE,
    },
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.ACTIVEREADY],
      value: MNO_STATUS.ACTIVEREADY,
    },
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.DEACTIVATED],
      value: MNO_STATUS.DEACTIVATED,
    },
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.RETIRED],
      value: MNO_STATUS.RETIRED,
    },
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.TESTREADY],
      value: MNO_STATUS.TESTREADY,
    },
    {
      label: MNO_STATUS_VALUE_LABEL_MAP[MNO_STATUS.UNRECOGNIZED],
      value: MNO_STATUS.UNRECOGNIZED,
    },
  ];
  return (
    <MultiSelect<MnoStatusFilter>
      sx={{ width: '100%', height: 'auto' }}
      data-testid={'mno-status-multiselect'}
      name="mnoStatus"
      value={mnoStatus}
      onChange={(value) => {
        setMNOStatus(value);
      }}
      showSelectAll={true}
      options={MNOStatusOptions}
      //@ts-ignore
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <Typography variant="text1" color="text.secondary">
              {t('simInventory.mnoFilterStatusPlaceholder')}
            </Typography>
          );
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1.5,
            }}
          >
            {selected.map((value: MnoStatusFilter) => (
              <span
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                <Chip
                  label={MNO_STATUS_VALUE_LABEL_MAP[value]}
                  variant="filled"
                  onDelete={(event) => {
                    setMNOStatus((curentStatuses) =>
                      curentStatuses.filter((status) => status !== value),
                    );
                  }}
                />
              </span>
            ))}
          </Box>
        );
      }}
    />
  );
};
