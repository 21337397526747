import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Routes } from 'Routes.constants';
import { CopyableLink } from 'common/components/Table/Link';
import { RsimProfileStatus } from '../../RSimApi/rSimApi.interface';
import { RSimInventorySimulateOutage } from './RSimInventorySimulateOutage';
import { RSimInventoryPollingSimStatus } from './RSimInventoryPollingSimStatus';
import { SimProfileDTO } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { SectionGridRow } from '../../../common/components/SectionGrid/SectionGridRow';
import { SectionGridItem } from '../../../common/components/SectionGrid/SectionGridItem';
import {
  rSimInventorySimDetailsGridMediumScreen,
  rSimInventorySimDetailsGridSmallScreen,
  simGridSizes,
  tagGridSizes,
} from './RSimInventoryDetailsPage.style';
import { TagDisplay } from 'tags/TagsDisplay';
import { Tag } from 'tags/services/tagsApi.types';
import { useMediaQuery } from '@mui/material';
import { CARD_TYPE, TagProvider } from '../../../tags/TagProvider';
import Box from '@mui/material/Box';
import { palette } from 'theme/palette';
import { PrimarySimGrid } from './components/PrimarySimGrid';
import { SecondarySimGrid } from './components/SecondarySimGrid';
import { useTheme } from '@mui/material/styles';
import { SimInventoryDetails } from '../../../simInventory/SimInventoryDetails/SimInventoryDetails';
import { SimInventoryCan } from 'permissions/PermissionProvider';
import { Actions, Subjects } from 'permissions/ability';
import { ErrorFallback } from 'common/components/Fallbacks/ErrorFallback';

type RSimInventorySimDetailsProps = {
  primarySimEntry: SimProfileDTO;
  secondarySimEntry: SimProfileDTO;
  rSimStatus: RsimProfileStatus;
  euicc: string;
};

export const RSimInventorySimDetails: React.FC<RSimInventorySimDetailsProps> = ({
  primarySimEntry,
  secondarySimEntry,
  rSimStatus,
  euicc,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const matchesMd = useMediaQuery('(min-width:900px)');

  return (
    <TagProvider type={CARD_TYPE.SIM}>
      <Box
        sx={{
          ...rSimInventorySimDetailsGridSmallScreen,
          [theme.breakpoints.up('md')]: {
            ...rSimInventorySimDetailsGridMediumScreen,
          },
          borderTop: `1px solid ${palette.border}`,
        }}
      >
        <div
          style={{
            gridArea: 'divider',
            borderRight: `1px solid ${palette.border}`,
          }}
        ></div>

        <PrimarySimGrid sx={{ gridArea: 'primary1' }} hideTopBorder={true}>
          {primarySimEntry?.iccid && primarySimEntry.iccid !== '-' ? (
            <>
              <Typography variant="h3" color="text.secondary">
                {t('common.primary')}
              </Typography>
              <CopyableLink
                value={primarySimEntry?.iccid!}
                to={`${Routes.simInventory}/${primarySimEntry?.iccid!}`}
                sx={{ mb: 2 }}
              />
              <SimInventoryDetails
                iccid={primarySimEntry?.iccid}
                rSimView
                testId={'primarySimInventory'}
                rSimViewSimProfileRow={
                  <>
                    <SectionGridRow hideTopBorder={true} title={t('simInventory.simProfile')}>
                      <>
                        <SectionGridItem
                          {...simGridSizes}
                          label={t('simInventory.profileStatus')}
                          testId={`profileStatus`}
                        >
                          <RSimInventoryPollingSimStatus
                            euicc={euicc!}
                            isPrimary={true}
                            rSimStatus={rSimStatus}
                          />
                        </SectionGridItem>
                        <SimInventoryCan I={Actions.simulate} a={Subjects.outage}>
                          <SectionGridItem
                            {...simGridSizes}
                            label={t('common.simulateOutage')}
                            testId={`simulateOutage`}
                          >
                            <RSimInventorySimulateOutage euicc={euicc!} isPrimary={true} />
                          </SectionGridItem>
                        </SimInventoryCan>
                      </>
                    </SectionGridRow>
                    <SectionGridRow hideTopBorder={true} sx={{ pb: 3 }}>
                      <SectionGridItem {...tagGridSizes} label={t('common.tags')} testId={`tags`}>
                        {
                          // @ts-ignore
                          !!primarySimEntry?.tags?.items &&
                          // @ts-ignore
                          primarySimEntry?.tags?.items.length > 0 ? (
                            <TagDisplay
                              id={primarySimEntry?.iccid!}
                              initialTags={
                                // @ts-ignore
                                primarySimEntry?.tags?.items.map((item: Tag) => item.name) || []
                              }
                              // @ts-ignore
                              hasMore={primarySimEntry?.tags?.hasMore || false}
                              shouldWrap={true}
                            />
                          ) : (
                            <span>-</span>
                          )
                        }
                      </SectionGridItem>
                    </SectionGridRow>
                  </>
                }
              />
            </>
          ) : (
            <ErrorFallback message={t('auth.somethingWrong')} />
          )}
        </PrimarySimGrid>
        <SecondarySimGrid sx={{ gridArea: 'secondary1' }} hideTopBorder={matchesMd}>
          {secondarySimEntry?.iccid && secondarySimEntry.iccid !== '-' ? (
            <>
              <Typography variant="h3" color="text.secondary">
                {t('common.backup')}
              </Typography>
              <CopyableLink
                value={secondarySimEntry?.iccid!}
                to={`${Routes.simInventory}/${secondarySimEntry?.iccid!}`}
                sx={{ mb: 2 }}
              />
              <SimInventoryDetails
                iccid={secondarySimEntry?.iccid}
                rSimView
                testId={'secondarySimInventory'}
                rSimViewSimProfileRow={
                  <>
                    <SectionGridRow hideTopBorder={true} title={t('simInventory.simProfile')}>
                      <>
                        <SectionGridItem
                          {...simGridSizes}
                          label={t('simInventory.profileStatus')}
                          testId={`profileStatus`}
                        >
                          <RSimInventoryPollingSimStatus
                            euicc={euicc!}
                            isPrimary={false}
                            rSimStatus={rSimStatus}
                          />
                        </SectionGridItem>
                        <SimInventoryCan I={Actions.simulate} a={Subjects.outage}>
                          <SectionGridItem
                            {...simGridSizes}
                            label={t('common.simulateOutage')}
                            testId={`simulateOutage`}
                          >
                            <RSimInventorySimulateOutage euicc={euicc!} isPrimary={false} />
                          </SectionGridItem>
                        </SimInventoryCan>
                      </>
                    </SectionGridRow>
                    <SectionGridRow hideTopBorder={true} sx={{ pb: 3 }}>
                      <SectionGridItem {...tagGridSizes} label={t('common.tags')} testId={`tags`}>
                        {
                          // @ts-ignore
                          !!secondarySimEntry?.tags?.items &&
                          // @ts-ignore
                          secondarySimEntry?.tags?.items.length > 0 ? (
                            <TagDisplay
                              id={secondarySimEntry?.iccid!}
                              initialTags={
                                // @ts-ignore
                                secondarySimEntry?.tags?.items.map((item: Tag) => item.name) || []
                              }
                              // @ts-ignore
                              hasMore={secondarySimEntry?.tags?.hasMore || false}
                              shouldWrap={true}
                            />
                          ) : (
                            <span>-</span>
                          )
                        }
                      </SectionGridItem>
                    </SectionGridRow>
                  </>
                }
              />
            </>
          ) : (
            <ErrorFallback message={t('auth.somethingWrong')} />
          )}
        </SecondarySimGrid>
      </Box>
    </TagProvider>
  );
};
