import { setMinimalPromiseTime } from 'common/utils/setMinimalPromiseTime';
import { SimInventoryData } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { Sorting } from 'common/hooks/useSorting';
import { SimInventoryFiltersV2 } from 'simInventory/models/SimProfile.model';
import { fetchSimProducts, fetchSimProductsPagination } from '../SimProductsApi/simProductsApi';
import { getSearchIds } from 'simInventory/Components/Inputs/SimIdSearchInput.util';
import { useHasFeatureFlag } from '../../../featureFlags/useHasFeatureFlag';
import { simProductsQueries } from '../Inventory/queries/simProductsQueries';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useDebounce } from 'usehooks-ts';

export const useSimProductsFetch = (
  filters: SimInventoryFiltersV2,
  sort: { columnName: string; sort: Sorting } | null = null,
  rowsPerPage: number,
  page: number,
) => {
  const tagsMigratedToProductsView = useHasFeatureFlag('TagsMigratedToProductsView');

  const { tags, searchText, chosenIdType, customFields, ...otherFilters } = filters;

  const debouncedSearchText = useDebounce(searchText, 500);

  const queryKey = simProductsQueries.list({
    searchText: debouncedSearchText,
    chosenIdType,
    page,
    rowsPerPage,
    ...otherFilters,
    tags,
    sorting: sort,
    customFields,
  });

  const { data: simProducts, isRefetching } = useSuspenseQuery<SimInventoryData>({
    queryKey: queryKey,
    queryFn: ({ signal }) => {
      return setMinimalPromiseTime(
        fetchSimProducts(
          page,
          rowsPerPage,
          {
            searchText: getSearchIds(searchText),
            chosenIdType: chosenIdType,
            tags,
            customFields,
            ...otherFilters,
          },
          sort,
          tagsMigratedToProductsView,
          signal,
        ),
      );
    },
    refetchOnWindowFocus: false,
  });

  const { status, data: pagination } = useQuery({
    queryKey: simProductsQueries.pagination({
      searchText: debouncedSearchText,
      chosenIdType,
      ...otherFilters,
      tags,
      sorting: sort,
      customFields,
    }),
    queryFn: ({ signal }) =>
      fetchSimProductsPagination(
        page,
        rowsPerPage,
        {
          searchText: getSearchIds(searchText),
          chosenIdType: chosenIdType,
          tags,
          customFields,
          ...otherFilters,
        },
        signal,
      ),
  });

  return {
    simProducts,
    pagination: {
      totalNumberOfItems: status === 'success' ? pagination?.totalNumberOfItems : -1,
    },
    queryKey,
    isRefetching,
  };
};
