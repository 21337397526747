import { atom, SetStateAction } from 'jotai';

export const createPaginationAtoms = () => {
  const pageFilterAtom = atom(0);
  const initialRowsPerPageFilterAtom = atom(25);
  const rowsPerPageFilterAtom = atom(
    (get) => get(initialRowsPerPageFilterAtom),
    (_, set, newValue: SetStateAction<number>) => {
      set(pageFilterAtom, 0);
      return set(initialRowsPerPageFilterAtom, newValue);
    },
  );

  return {
    pageFilterAtom,
    rowsPerPageFilterAtom,
  };
};
