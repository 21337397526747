import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { mapRSimFiltersToURLSearchParams } from '../../utils/filtersToURLSearcParamsMapper';
import { useMounted } from 'common/useMounted';
import { rsimFiltersAtom } from 'atoms/filters/rsim-products';

export const RSimFiltersToURLParser = ({ children }: any) => {
  const [, setSearhParams] = useSearchParams();
  const isMounted = useMounted();

  const filters = useAtomValue(rsimFiltersAtom);

  useEffect(() => {
    if (isMounted) {
      setSearhParams(mapRSimFiltersToURLSearchParams(filters));
    }
  }, [filters]);
  return <>{children}</>;
};
