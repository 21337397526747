import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import './i18n/i18n';
import './index.css';
import { LicenseInfo } from '@mui/x-license-pro';
import ReactGA from 'react-ga4';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY || '');
ReactGA.initialize(process.env.REACT_APP_GA_KEY || '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
