import { useMemo } from 'react';
import { useCustomFieldQuery } from './useCustomFieldQuery';

export const useEnabledCustomFieldQuery = () => {
  const { data, ...props } = useCustomFieldQuery();

  const enabledCustomFields = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter((f) => f.enabled);
  }, [data]);

  return {
    ...props,
    data: enabledCustomFields,
  };
};
