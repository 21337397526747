import { Configuration } from 'common/components/ConfigurableTable/hooks/usePrepareConfiguration';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { useMemo } from 'react';
import { useRSimsSimsContext } from 'rSimInventory/hooks/useRSimsSimsContext';
import { getSessionStatusTranslatedLabel, SessionStatusUi } from 'simInventory/utils/sessionStatus';
import { ValuesTypes } from 'simInventory/SimInventoryApi/simInventoryApi.interface';
import { Actions, Subjects } from 'permissions/ability';
import { useTranslation } from 'react-i18next';

const FORMATERS_MAP: Partial<Record<ValuesTypes, (value: any) => string>> = {
  sessionStatus: (value: any) => getSessionStatusTranslatedLabel(value as SessionStatusUi, '-'),
};

const SIZE_CONFIGURATION: Partial<
  Record<ValuesTypes, { width?: number; minWidth?: number; maxWidth?: number }>
> = {
  iccid: {
    width: 180,
  },
  currentIpAddress: {
    width: 100,
  },
  managementIpAddress: {
    width: 120,
  },
  commsPlan: {
    width: 240,
  },
  mobileNetworkOperator: {
    width: 240,
  },
  mnoStatus: {
    width: 150,
  },
  msisdn: {
    width: 150,
  },
  liveDataUsage: {
    width: 150,
  },
  inMinuteUsage: {
    width: 150,
  },
  outMinuteUsage: {
    width: 150,
  },
  inTextUsage: {
    width: 150,
  },
  outTextUsage: {
    width: 150,
  },
  sessionStartTime: {
    width: 150,
  },
  connectionId: {
    width: 150,
  },
  sessionStatus: {
    width: 120,
  },
};

export const useColumnConfiguration = () => {
  const productsViewEnabled = useHasFeatureFlag('ProductsView');
  const { data } = useRSimsSimsContext();
  const { t } = useTranslation();

  const idLabelMap: Partial<Record<ValuesTypes, string>> = useMemo(
    () => ({
      iccid: t('common.iccid'),
      imsi: t('common.imsi'),
      msisdn: t('common.msisdn'),
      currentIpAddress: t('products.sim.lastKnownIP'),
      managementIpAddress: t('common.managementIp'),
      mnoRatePlan: t('common.plan'),
      organisation: t('common.organisation'),
      label: t('common.label'),
      mnoStatus: t('simInventory.mnoStatus'),
      mnoStatusEntryTime: t('simInventory.mnoStatusEntryTime_combined'),
      customerCommsPlan: t('simInventory.customerCommsPlan'),
      commsPlan: t('simInventory.commsPlan'),
      mobileNetworkOperator: t('simInventory.mobileNetworkOperator'),
      accountName: t('common.accountName'),
      accountRef: t('common.accountNumber'),
      simStatus: t('simInventory.simStatus'),
      liveDataUsage: t('common.usage'),
      inMinuteUsage: !productsViewEnabled
        ? t('common.inMinuteUsage')
        : t('common.inMinuteUsageProduct'),
      outMinuteUsage: !productsViewEnabled
        ? t('common.outMinuteUsage')
        : t('common.outMinuteUsageProduct'),
      inTextUsage: !productsViewEnabled ? t('common.inTextUsage') : t('common.inTextUsageProduct'),
      outTextUsage: !productsViewEnabled
        ? t('common.outTextUsage')
        : t('common.outTextUsageProduct'),
      sessionStartTime: t('simInventory.sessionStartTime'),
      sessionEndTime: t('simInventory.sessionEndTime'),
      sessionStatus: t('simInventory.sessionStatus'),
      sessionStatusLastUpdated: t('simInventory.sessionStatusLastUpdated_combined'),
      connectionId: t('simInventory.connectionId'),
      orderNumber: t('simInventory.orderNumber'),
      tags: t('common.tag'),
    }),
    [],
  );

  const configuration: Configuration[] = useMemo(() => {
    const staticHeadersBegining: Configuration[] = [
      {
        field: 'status',
        headerName: '',
        width: 50,
        resizable: false,
        sortable: false,
        pinnable: false,
      },
    ];

    let headers = data?.headers;

    if (productsViewEnabled) {
      headers = [
        {
          id: 'iccid',
          types: ['iccid', 'imsi'],
        },
        {
          id: 'sessionStatus&sessionStatusLastUpdated',
          types: ['sessionStatus', 'sessionStatusLastUpdated'],
        },
        {
          id: 'sessionStartTime&sessionEndTime',
          types: ['sessionStartTime', 'sessionEndTime'],
        },
        {
          id: 'mnoStatus',
          types: ['mnoStatus'],
        },
        {
          id: 'currentIpAddress&managementIpAddress',
          types: ['currentIpAddress', 'managementIpAddress'],
        },
        {
          id: 'commsPlan',
          types: ['commsPlan'],
        },
        {
          id: 'mobileNetworkOperator',
          types: ['mobileNetworkOperator'],
        },
        {
          id: 'liveDataUsage',
          types: ['liveDataUsage'],
        },
        {
          id: 'inMinuteUsage&outMinuteUsage',
          types: ['outMinuteUsage', 'inMinuteUsage'],
        },
        {
          id: 'inTextUsage&outTextUsage',
          types: ['outTextUsage', 'inTextUsage'],
        },
        {
          id: 'msisdn',
          types: ['msisdn'],
        },
      ];
    }

    const obtainedHeaders =
      headers?.map((header: any) => {
        const field = header.types[0] as ValuesTypes;

        const baseConfiguration: Configuration = {
          field: field,
          headerName: idLabelMap[field],
          //@ts-ignore
          valueGetter: FORMATERS_MAP[field],
          resizable: false,
          sortable: false,
          pinnable: false,
          width: SIZE_CONFIGURATION[field]?.width,
          minWidth: SIZE_CONFIGURATION[field]?.minWidth,
          maxWidth: SIZE_CONFIGURATION[field]?.maxWidth,
        };

        if (header.types[1]) {
          const subField = header.types[1] as ValuesTypes;
          baseConfiguration.subField = {
            field: subField,
            headerName: idLabelMap[subField] || subField,
            valueGetter: FORMATERS_MAP[subField],
          };
        }

        return baseConfiguration;
      }) || [];

    const staticHeadersEnd: Configuration[] = [
      {
        field: 'simulateOutage',
        headerName: t('common.simulateOutage'),
        width: 150,
        resizable: false,
        sortable: false,
        pinnable: false,
        permission: {
          action: Actions.simulate,
          subject: Subjects.outage,
        },
      },
    ];

    return [...staticHeadersBegining, ...obtainedHeaders, ...staticHeadersEnd];
  }, [data?.headers]);

  return configuration;
};
