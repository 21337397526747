import React from 'react';
import { Tooltip } from './Tooltip';

export type TableTooltipProps = {
  children: React.ReactElement;
  value: string;
};

export const TableTooltip: React.FC<TableTooltipProps> = ({ children, value }) => {
  return (
    <Tooltip
      showArrow={true}
      placement={'bottom'}
      enterDelay={1000}
      enterTouchDelay={1000}
      enterNextDelay={1000}
      variant="dark"
      title={value}
    >
      {children}
    </Tooltip>
  );
};
