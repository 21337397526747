import { useTranslation } from 'react-i18next';
import { ActivationRequestFailedError, provisionRsim } from './api/rSimActionsApi';
import { ConfirmActionDialog } from 'common/components/ConfigurableTable/Components/Actions/ConfirmActionDialog';
import React, { useState } from 'react';
import {
  ActivationError,
  ActivationSnackbar,
} from 'common/components/Activation/ActivationSnackbar';
import { useMutation } from '@tanstack/react-query';
import { AuthError } from 'auth/utils';

type ActivationDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any;
};

export const ActivationDialog = ({ data, open, onClose }: ActivationDialogProps) => {
  const { t } = useTranslation();

  const [error, setError] = useState<ActivationError | null>(null);

  const { mutate } = useMutation({
    mutationKey: ['rsim-provisisioning'],
    mutationFn: async () => {
      await provisionRsim([data.connectionId], true);
    },
    retry: 0,
    onError: (error) => {
      if (error instanceof AuthError) {
        throw error;
      } else if (error instanceof ActivationRequestFailedError) {
        setError({
          title: t('common.activationRequestFailed'),
          description: [
            t('common.anotherRequestStillInProgress'),
            t('simInventory.pleaseTryAgainLater'),
          ],
        });
      } else {
        setError({ title: error.message, description: [] });
      }
    },
  });

  return (
    <>
      <ConfirmActionDialog
        open={open}
        data={data}
        onClose={onClose}
        submit={async () => mutate()}
        message={`${t('rSimInventory.activationEid')} ${data?.euicc}`}
        title={t('rSimInventory.activation')}
      />
      <ActivationSnackbar error={error} setError={setError} />
    </>
  );
};
